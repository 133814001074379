import Layout from '@/layouts/EmptyLayout'
export default [{
  path: '/users',
  name: 'users',
  meta: {
    layout: 'main',
    public: true
  },
  component: () => import('@/views/users/index')
}]
