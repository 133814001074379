import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/settings/medicalDirections",
        method: "get",
        params: params
    });
}

export function inventory(params) {
    return request({
        url: "/settings/medicalDirections/inventory",
        method: "get",
        params
    });
}

export function show(id) {
    return request({
        url: `/settings/medicalDirections/${id}`,
        method: "get"
    });
}

export function store(data) {
    return request({
        url: "/settings/medicalDirections",
        method: "post",
        data
    });
}

export function update(data) {
    return request({
        url: `/settings/medicalDirections/${data.id}`,
        method: "put",
        data
    });
}

export function destroy(id) {
    return request({
        url: `/settings/medicalOrganizationTypes/${id}`,
        method: "delete"
    });
}

export function getAllDirections(params) {
    return request({
        url: "/settings/medicalDirections/getAllDirections",
        method: "get",
        params: params
    });
}
