import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/medicalDirection",
        name: "medicalDirection",
        meta: {
            layout: "main",
            public: true
        },
        component: () =>
            import("@/views/settingsBlocks/medicalDirection/index")
    }
];
