import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import store from "@/store/index";
import { model } from "./properties/model";
import { i18n } from "@/utils/i18n";

export const mutations = {
    SET_LIST: (state, data) => {state.list = data},
    SET_INVENTORY: (state, data) => (state.inventory = data),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value;
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order;
    },
    SET_MODEL: (state, licenseRequirement) => {
        state.model = licenseRequirement;
    },
	 EDIT_LIST: (state, data) => {
		state.model.id  = data.id;
		state.model.license_requirement_id = data.license_requirement_id;
		state.model.type_of_employee_id = data.type_of_employee_id;
		state.model.direction_id = data.direction_id;

		state.educations = [], state.qualification_categories = [], state.academic_deegres = [];

		if (data.items && _.isArray(data.items)) {
			data.items.forEach(item => {
				if (item.name == 'type_of_employee' && item.interval) {
					state.model.li_until = item.interval.li_until;
				}
				else if (item.name == 'passport_and_series') {
					state.model.passport_status = item.li_status;
				}
				else if (item.name == 'inn') {
					state.model.inn_status = item.li_status;
				}
				else if (item.name == 'region') {
					state.model.region_status = item.li_status;
				}
				else if (item.name == 'certificate') {
					state.model.certificate_status = item.li_status;
				}
				else if (item.name == 'diploma') {
					state.model.diploma_status = item.li_status;
				}
				else if (item.name == 'work_experience' && item.interval) {
					state.model.work_experience_from = item.interval.li_from;
					state.model.work_experience_until = item.interval.li_until;
					state.model.work_experience_status = item.li_status;
				}
				else if (item.name == 'educations') {
					if (!_.isEmpty(item.educations)) {
						item.educations.forEach(elem => {
							state.educations.push({
								id: elem.id,
								education_id: elem.education_id,
								education_status: elem.education_status
							});
						});
					}
				}
				else if (item.name == 'qualification_category') {
					if (!_.isEmpty(item.qulification_categories)) {
						item.qulification_categories.forEach(elem => {
							state.qualification_categories.push({
								id: elem.id,
								qualification_category_id: elem.qualification_category_id,
								qualification_category_status: elem.qualification_category_status
							});
						});
					}
				}
				else if (item.name == 'academic_degree') {
					if (!_.isEmpty(item.academic_degrees)) {
						item.academic_degrees.forEach(elem => {
							state.academic_deegres.push({
								id: elem.id,
								academic_degree_id: elem.academic_degree_id,
								academic_degree_status: elem.academic_degree_status
							});
						});
					}
				}
				else if (item.name == 'steerage') {
					state.model.steerage_status = item.li_status;
					state.model.steerage = item.steerage.li_type;
				}
			});
		}
	 },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },
	 EMPTY_ITEMS: (state) => {
		state.educations = [];
		state.qualification_categories = [];
		state.academic_deegres = [];
  	},
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
};
