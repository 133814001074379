<template>
    <div class="main">
        <div class="page-main-header">
            <div class="main-header-left">
                <div class="logo-wrapper">
                    <!-- <img src="./../../public/img/logo.png" /> -->
                    <div class="logo-img"></div>
                </div>
                <div class="hamburger-icons">
                    <i @click="collapse" class="el-icon-s-operation on"></i>
                </div>
            </div>
            <div class="main-header-right">
                <div class="main-header-right-sorche"></div>
                <div class="main-header-right-icons">
                    <div class="icons-h ar-ber">
                        <router-link  :to="{ name: 'submitApplication' }">
                            <i class="el-icon-plus"></i>
                            Ariza berish
                        </router-link>
                    </div>
                    <div class="icons-h">
                        <i
                            class="el-icon-full-screen"
                            id="full"
                            @click="fullscreen = !fullscreen"
                        ></i>
                    </div>
                   
                    <div class="icons-h">
                        <div class="theme-switch-wrapper">
                            <label class="theme-switch" for="checkbox">
                                <input type="checkbox" id="checkbox" />
                                <i class="el-icon-moon"></i>
                            </label>
                        </div>
                    </div>
                    <div class="icons-h"><i class="el-icon-bell"></i></div>
                    <!-- <div class="icons-h">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-message-square"
                        >
                            <path
                                d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"
                            ></path>
                        </svg>
                    </div> -->
                    <div class="login-link">
                        <a @click.prevent="logout">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-log-out"
                            >
                                <path
                                    d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"
                                ></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                            
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-body-wrapper sidebar-icon">
            <div class="left-bar main-nav">
                <el-menu
                    class="el-menu-vertical-demo"
                    @open="handleOpen"
                    @close="handleClose"
                    :collapse="isCollapse"
                >
                    <router-link v-can="['dashboard.index']" :to="{ name: 'Home' }">
                        <el-menu-item index="1">
                            <div class="left-bar-icon">
                                <i class="el-icon-data-analysis"></i>
                            </div>
                            <span slot="title" class="mer pt">Dashboard</span>
                        </el-menu-item>
                    </router-link>

                    <router-link v-can="['statements.index']" :to="{ name: 'applications' }">
                        <el-menu-item index="7">
                            <div class="left-bar-icon">
                                <i class="el-icon-edit-outline"></i>
                            </div>
                            <span slot="title" class="mer pt">Arizalar</span>
                        </el-menu-item>
                    </router-link>

                    <router-link v-can="['licences.index']" :to="{ name: 'confirm' }">
                        <el-menu-item index="2">
                            <div class="left-bar-icon">
                                <i class="el-icon-document-checked"></i>
                            </div>
                            <span slot="title" class="mer pt">
                                Tasdiqlash uchun
                            </span>
                        </el-menu-item>
                    </router-link>

                    <router-link
                        :to="{ name: 'clinics' }"
                        v-can="['clinics.index']"
                    >
                        <el-menu-item index="4">
                            <div class="left-bar-icon">
                                <i class="el-icon-office-building"></i>
                            </div>
                            <span slot="title" class="mer pt">
                                Tibbiy muassasalar
                            </span>
                        </el-menu-item>
                    </router-link>

                    <router-link
                        :to="{ name: 'doctorTable' }"
                        v-can="['doctors.index']"
                    >
                        <el-menu-item index="3">
                            <div class="left-bar-icon">
                                <i class="el-icon-user"></i>
                            </div>
                            <span slot="title" class="mer pt">Tibbiy Xodimlar</span>
                        </el-menu-item>
                    </router-link>

                    <router-link
                        :to="{ name: 'medicalTechniques' }"
                        v-can="['medicalTechniques.index']"
                    >
                        <el-menu-item index="5">
                            <div class="left-bar-icon">
                                <i class="el-icon-cpu"></i>
                            </div>
                            <span slot="title" class="mer pt">
                                Tibbiy texnikalar
                            </span>
                        </el-menu-item>
                    </router-link>

                    <router-link
                        :to="{ name: 'medicalEquipments' }"
                        v-can="['medicalEquipments.index']"
                    >
                        <el-menu-item index="6">
                            <div class="left-bar-icon">
                                <i class="el-icon-cpu"></i>
                            </div>
                            <span slot="title" class="mer pt">
                                Tibbiy uskunalar
                            </span>
                        </el-menu-item>
                    </router-link>

                    <router-link v-can="['services.index']" :to="{ name: 'services' }">
                        <el-menu-item index="7">
                            <div class="left-bar-icon">
                                <i class="el-icon-first-aid-kit"></i>
                            </div>
                            <span slot="title" class="mer pt">Tibbiy Xizmatlar</span>
                        </el-menu-item>
                    </router-link>

                    <router-link v-can="['documents.index']" :to="{ name: 'documents' }">
                        <el-menu-item index="7">
                            <div class="left-bar-icon">
                                <i class="el-icon-folder-add"></i>
                            </div>
                            <span slot="title" class="mer pt">Tibbiy Hujjatlar</span>
                        </el-menu-item>
                    </router-link>

                    <router-link v-can="['requirements.index']" :to="{ name: 'licenseRequiments' }">
                        <el-menu-item index="7">
                            <div class="left-bar-icon">
                                <i class="el-icon-suitcase-1"></i>
                            </div>
                            <span slot="title" class="mer pt">Litsenziya talablari</span>
                        </el-menu-item>
                    </router-link>

                    <el-submenu index="9" v-can="['users.index']">
                        <template slot="title">
                            <div class="left-bar-icon">
                                <i class="el-icon-medal"></i>
                            </div>
                            <span slot="title" class="mer">HR</span>
                        </template>
                        <el-menu-item-group>
                            <!-- <span slot="title">Group One</span> -->
                            <!-- //v-can="['users.index']"  v-can="['roles.index']"-->
                            <router-link :to="{ name: 'users' }">
                                <el-menu-item index="9-1">
                                    Foydalanuvchilar
                                </el-menu-item>
                            </router-link>
                            <router-link :to="{ name: 'rol' }">
                                <el-menu-item index="9-2"> Rol </el-menu-item>
                            </router-link>
                            <!-- <el-menu-item index="9-3">
                                Kirish huquqlari
                            </el-menu-item> -->
                        </el-menu-item-group>
                    </el-submenu>

                    <router-link v-can="['settings.index']" :to="{ name: 'settings' }">
                        <el-menu-item index="10">
                            <div class="left-bar-icon">
                                <i class="el-icon-setting"></i>
                            </div>
                            <span slot="title" class="mer pt">Sozlamalar</span>
                        </el-menu-item>
                    </router-link>

                    <a @click.prevent="logout">
                        <el-menu-item index="11">
                            <div class="left-bar-icon">
                                <i class="el-icon-lock"></i>
                            </div>
                            <span slot="title" class="mer pt">Chiqish</span>
                        </el-menu-item>
                    </a>
                </el-menu>
            </div>

            <div class="page-body">
                <router-view />
            </div>
        </div>
        <!-- end page-body-wrapper sidebar-icon -->
    </div>
</template>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 260px;
}
.el-menu-vertical-demo {
    height: calc(100vh - 65px);
}
</style>

<script>
// import Navbar from "./components/header";
// import LeftBar from "./components/left_bar";
import { mapActions, mapGetters } from "vuex";
export default {
    // components: { Navbar, LeftBar },
    data() {
        return {
            isCollapse: false,
            fullscreen: false,
            value2: true,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            slug: "auth/slug",
        }),
    },
    methods: {
        ...mapActions({
            userLogout: "auth/logout",
        }),
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        collapse() {
            this.isCollapse = !this.isCollapse;
        },

        logout() {
            this.$confirm(
                "Вы уверены, что выйдете из системы?",
                "Авторизоваться",
                {
                    confirmButtonText: "Да",
                    cancelButtonText: "Отмен",
                    type: "warning",
                }
            )
                .then(() => {
                    this.userLogout()
                        .then(() => {
                            window.location.reload(true);
                        })
                        .catch((error) => {
                            window.location.reload(true);
                            reject(error);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: "Операция отменена",
                    });
                });
        },
    },
    watch: {
        fullscreen(enterFullscreen) {
            if (enterFullscreen) {
                var element = document.body;
                var requestMethod =
                    element.requestFullScreen ||
                    element.webkitRequestFullScreen ||
                    element.mozRequestFullScreen ||
                    element.msRequestFullScreen;

                if (requestMethod) {
                    requestMethod.call(element);
                } else if (typeof window.ActiveXObject !== "undefined") {
                    var wscript = new ActiveXObject("WScript.Shell");
                    if (wscript !== null) {
                        wscript.SendKeys("{F11}");
                    }
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    /* IE/Edge */
                    document.msExitFullscreen();
                }
            }
        },
    },
    mounted() {
        const toggleSwitch = document.querySelector(
            '.theme-switch input[type="checkbox"]'
        );
        const currentTheme = localStorage.getItem("theme");

        if (currentTheme) {
            document.documentElement.setAttribute("data-theme", currentTheme);

            if (currentTheme === "dark") {
                toggleSwitch.checked = true;
            }
        }

        function switchTheme(e) {
            if (e.target.checked) {
                document.documentElement.setAttribute("data-theme", "dark");
                localStorage.setItem("theme", "dark");
            } else {
                document.documentElement.setAttribute("data-theme", "light");
                localStorage.setItem("theme", "light");
            }
        }

        toggleSwitch.addEventListener("change", switchTheme, false);
    },
};
</script>
<style>
.left-bar .el-menu-item:focus,
.left-bar .el-menu-item:hover {
    background-color: #0087af;
    color: #d0efe9 !important;
}
</style>