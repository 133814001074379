export const model = {
    id: null,
    license_requirement_id: null,
    type_of_employee_id: null,
    li_until: null,
    direction_id: null,
    passport_status: null,
    inn_status: null,
    region_status: null,
    work_experience_from: null,
    work_experience_until: null,
    work_experience_status: null,
    steerage: null,
    steerage_status: null,
	 certificate_status: null,
	 diploma_status: null,
    created_at: "",
    updated_at: "",
};
