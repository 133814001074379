import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "@/utils/permissions";
import  '@/utils/v-can';
import router from "./router";
import store from "./store";
import { i18n } from "./utils/i18n";
import "./plugins/element.js";
import "./../public/css/index.css";
import "./../public/css/added.css";
import "@/utils/library";
import lang from "element-ui/lib/locale/lang/ru-RU";
import draggable from "vuedraggable";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import locale from "element-ui/lib/locale";
const files = require.context("./components/", true, /\.vue$/i);
files
    .keys()
    .map((key) =>
        Vue.component(key.split("/").pop().split(".")[0], files(key).default)
    );
locale.use(lang);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    draggable,
    render: (h) => h(App),
}).$mount("#app");
