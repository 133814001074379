import { state } from "./state";

export const getters = {
  list: state => state.list,
  inventory: state => state.inventory,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort,
  informations: state => state.informations,
  work_experiences: state => state.work_experiences,
  certificates: state => state.certificates,
  academic_degrees: state => state.academic_degrees,
  rank: state => state.rank

};
