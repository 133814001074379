<template>
    <div>
        <!-- <el-date-picker
            :value="value"
            @input="dispatch"
            type="date"
            :placeholder="placeholder || $t('message.datas')"
            :size="size"
            :class="className"
            :format="dateFormat"
            :value-format="valueFormat"
        >
        </el-date-picker> -->
        <el-date-picker
            :value="value"
            @input="dispatch"
            type="date"
            :placeholder="placeholder || $t('message.datas')"
            :size="size"
            :class="className"
            :format="dateFormat"
            :value-format="valueFormat"
        >
        </el-date-picker>
    </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
export default {
    name: "user_status",
    props: {
        size: {
            default: "small",
        },
        placeholder: {
            default: null,
        },
        date: {
            default: null,
        },
        dateFormat: {
            type: String,
            default: "dd.MM.yyyy",
        },
        valueFormat: {
            type: String,
            default: "dd.MM.yyyy",
        },
        className: {
            type: String,
            default: "w-100",
        },
    },
    data() {
        return {
            value: null,
        };
    },
    watch: {
        date: {
            handler: function () {
                this.value = this.date;
            },
            immediate: true,
        },
    },
    methods: {
        dispatch(e) {
            this.$emit("input", e);
            this.value = e;
        },
    },
};
</script>
