<template>
    <div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
                <Direction :directions="directions"></Direction>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
                <TableInfo :doctors="doctors"></TableInfo>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import _ from 'lodash';
import TableInfo from "./left.vue";
import Direction from "./right.vue";
export default {
    name: "Home",
    props:{
        list: {
            type: Object,
            required: true
        }
    },
    components: {
        TableInfo,
        Direction
    },
    computed: {
        doctors: function(){
            let doctors = [];
            if (this.list && _.size(this.list['doctors'])) {
                doctors = this.list['doctors'];
                return doctors;
            }
            return doctors;
        },
        directions: function(){
            let directions = [];
            if (this.list && _.size(this.list.directions)) {
                directions = this.list.directions;
                return directions;
            }
            return directions;
        }
    },
};
</script>
