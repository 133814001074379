<template>
    <div class="home-index">
        <HomeSliderDoctor v-loading="loadingData" :list="list"></HomeSliderDoctor>

        <HomeSliderClinck></HomeSliderClinck>

        <div class="home-top-info-license">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
                    <div class="card o-hidden border-0">
                        <div class="bg-danger2 b-r-4 card-body">
                            <div class="media static-top-widget">
                                <div class="hop-top-icon">
                                    <i class="el-icon-folder"></i>
                                </div>
                                <div class="media-body">
                                    <span class="m-0">Yangi arizalar</span>
                                    <div class="soni mb-0 counter">{{ apps_count.new_apps }}</div>
                                    <i class="el-icon-folder postion-icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
                    <div class="card o-hidden border-0">
                        <div class="bg-danger2 b-r-4 card-body">
                            <div class="media static-top-widget">
                                <div class="hop-top-icon">
                                    <i class="el-icon-folder-opened"></i>
                                </div>
                                <div class="media-body">
                                    <span class="m-0">Ko'rib chiqilmoqda </span>
                                    <div class="soni mb-0 counter">{{ apps_count.review_apps }}</div>
                                    <i
                                        class="
                                            el-icon-folder-opened
                                            postion-icon
                                        "
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
                    <div class="card o-hidden border-0">
                        <div class="bg-danger2 b-r-4 card-body">
                            <div class="media static-top-widget">
                                <div class="hop-top-icon">
                                    <i class="el-icon-folder-checked"></i>
                                </div>
                                <div class="media-body">
                                    <span class="m-0">
                                        Ko'rib chiqilgan arizalar
                                    </span>
                                    <div class="soni mb-0 counter">6</div>
                                    <i
                                        class="
                                            el-icon-folder-checked
                                            postion-icon
                                        "
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
                    <div class="card o-hidden border-0">
                        <div class="bg-danger2 b-r-4 card-body">
                            <div class="media static-top-widget">
                                <div class="hop-top-icon">
                                    <i class="el-icon-folder-delete"></i>
                                </div>
                                <div class="media-body">
                                    <span class="m-0">
                                        Muddati tugayotgan arizalar
                                    </span>
                                    <div class="soni mb-0 counter">{{apps_count.is_expiring_apps}}</div>
                                    <i
                                        class="
                                            el-icon-folder-delete
                                            postion-icon
                                        "
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
                    <div class="card o-hidden border-0">
                        <div class="bg-primary2 b-r-4 card-body">
                            <div class="media static-top-widget">
                                <div class="hop-top-icon">
                                    <i class="el-icon-receiving"></i>
                                </div>
                                <div class="media-body">
                                    <span class="m-0"
                                        >Umumiy berilgan litsenziyalar
                                        soni</span
                                    >
                                    <div class="soni mb-0 counter">{{apps_count.successfully_apps}}</div>
                                    <i
                                        class="el-icon-receiving postion-icon"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
                    <div class="card o-hidden border-0">
                        <div class="bg-danger22 b-r-4 card-body">
                            <div class="media static-top-widget">
                                <div class="hop-top-icon">
                                    <i class="el-icon-circle-close"></i>
                                </div>
                                <div class="media-body">
                                    <span class="m-0"
                                        >Muddati tugayotgan litsenziyalar
                                        soni</span
                                    >
                                    <div class="soni mb-0 counter">6</div>
                                    <i
                                        class="
                                            el-icon-circle-close
                                            postion-icon
                                        "
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- end home-top-info-license -->

        <HomeLicense></HomeLicense>

        <HomeDoctor></HomeDoctor>

        <HomeTrinity></HomeTrinity>

        <HomeDoctorDirection :list="list"></HomeDoctorDirection>

        <HomeDoctorType></HomeDoctorType>

        <HomeTechnique></HomeTechnique>

        <HomeEquipment></HomeEquipment>

        <HomeCategory></HomeCategory>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import _ from 'lodash';
import HomeSliderDoctor from "./homeSliderDoctor/index";
import HomeSliderClinck from "./homeSliderClinck/index";
import HomeDoctor from "./homeDoctor/index";
import HomeDoctorDirection from "./homeDoctorDirection/index";
import HomeDoctorType from "./homeDoctorType/index";
import HomeLicense from "./homeLicense/index";
import HomeTrinity from "./homeTrinity/index";
import HomeEquipment from "./homeEquipment/index";
import HomeTechnique from "./homeTechnique/index";
import HomeCategory from "./homeCategory/index";
export default {
    name: "Home",
    components: {
        HomeSliderDoctor,
        HomeSliderClinck,
        HomeDoctor,
        HomeLicense,
        HomeDoctorDirection,
        HomeDoctorType,
        HomeTrinity,
        HomeEquipment,
        HomeTechnique,
        HomeCategory,
    },
    data() {
        return {
            loadingData: false,
            list: {},
            apps_count: {}
        }
    },
    created(){
        this.load();
    },
    methods:{
        ...mapActions({
            getDoctors: "dashboard/getDoctors"
        }),
        load(){
            if (!this.loadingData) {
                this.loadingData = true;
                this.getDoctors().then((res) => {
                    this.loadingData = false;
                    if (res.data) {
                        this.list = res.data;
                        this.apps_count = res.data.apps_count
                    }  
                }).catch((err) => {
                    this.loadingData = false;
                    console.log(err);
                });
            }
        },
    }
};
</script>
<style>
    .apexcharts-legend-text,
    .apexcharts-text{
        fill: var(--color-text) !important;
        color:  var(--color-text) !important;
    }
    .card.item-doctor p{
        color: var(--color-text);
    }
</style>