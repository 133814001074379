export const model = {
    id: null,
    license_requirement_id: null,
    technique_type_id: null,
    until: null,
    series_condition: null,
    series_status: false,
    passport: null,
    passport_condition: null,
    passport_status: false,
    manual: null,
    manual_condition: null,
    manual_status: false,
    document: null,
    document_condition: null,
    document_status: false,
    document_number_condition: null,
    document_number_status: false,
    certificate: null,
    certificate_condition: null,
    certificate_status: false,
    certificate_number_condition: null,
    certificate_number_status: false,
    property_ownership_id: null,
    property_ownership_condition: null,
    property_ownership_status: false,
    condition_id: null,
    condition: null,
    condition_status: false,
    date_of_manufacture: null,
    date_of_manufacture_condition: null,
    date_of_manufacture_status: false,
    created_at: "",
    updated_at: "",
};
