import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/documents",
        name: "documents",
        children:[
            {
                path: "documents/images",
                name: "docImages",
                meta: {
                    layout: "main",
                    public: true,
                },
                component: () => import("@/views/documents/components/images"),
            },
        ],
        meta: {
            layout: "main",
            public: true
        },
        component: () => import("@/views/documents/index")
    }
];
