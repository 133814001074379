export const filter = {
    id: null,
    clinic_id: null,
    filial_id: null,
    name: "",
    surname: "",
    lastname: "",
    id_number: "",
    passport_series_and_number: "",
    work_tel_number: "",
    mobile_tel_number: "",
    region_id: null,
    city_district_id: null,
    types_of_employee_id: null,
    created_at: "",
    updated_at: "",
    search: "",
};
