<template>
    <div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <typesOfEmployee :types="types"></typesOfEmployee>
            </el-col>
            <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
                <TableInfo :doctors="doctors"></TableInfo>
            </el-col> -->
        </el-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import _ from 'lodash';
import TableInfo from "./left.vue";
import typesOfEmployee from "./right.vue";
export default {
    name: "Home",
    components: {
        TableInfo,
        typesOfEmployee
    },
    data(){
        return {
            loadingData: false,
            types: []
        }
    },
    created(){
        let debounce_func = _.debounce(this.load, 500);
        debounce_func();
    },
    methods:{
        ...mapActions({
            getTypes: "dashboard/getTypes"
        }),
        load(){
            if (!this.loadingData) {
                this.getTypes().then((res) => {
                    if (res.data) {
                        this.types = res.data.types;
                    }  
                }).catch((err) => {
                    console.log(err);
                });
            }
        },
    }
};
</script>
