import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/cityDistricts",
        name: "cityDistricts",
        meta: {
            layout: "main",
            public: true,
        },
        component: () => import("@/views/settingsBlocks/cityDistricts/index"),
    },
];
