export default [
    {
        path: "/clinics",
        name: "clinics",
        meta: {
            layout: "main",
            public: true,
        },
        component: () => import("@/views/clinics/index"),
    },
];
