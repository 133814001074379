import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/settings/regions",
        method: "get",
        params: params,
    });
}

export function inventory(params) {
    return request({
        url: "/settings/regions/inventory",
        method: "get",
        params,
    });
}

export function show(id) {
    return request({
        url: `/settings/regions/${id}`,
        method: "get",
    });
}

export function filteredCityDistrict(params) {
    return request({
        url: `/settings/regions/filteredCityDistricts`,
        method: "get",
        params
    });
}

export function store(data) {
    return request({
        url: "/settings/regions",
        method: "post",
        data,
    });
}

export function update(data) {
    return request({
        url: `/settings/regions/${data.id}`,
        method: "put",
        data,
    });
}

export function destroy(id) {
    return request({
        url: `/settings/regions/${id}`,
        method: "delete",
    });
}
