<template>
    <div class="filter_table" style="margin-right: 115px">
        <el-button
            class="asosy-btn-d"
            style="margin-right: 2px; margin-top: 10px"
            @click="create()"
        >
            Qo'shish
        </el-button>
        <el-dropdown class="setting-cheek ml-2">
            <el-button size="small" icon="el-icon-setting"></el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="(column, index) in columns"
                    :key="'drop' + index"
                >
                    <el-checkbox
                        :checked="column.show"
                        @change="column.show = !column.show"
                        >{{ column.title }}
                    </el-checkbox>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
export default {
    props: {
        columns: {
            type: Object,
        },
        can_create: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        check: function (column, event) {
            this.$emit("c-change", {
                key: column,
                value: event,
            });
        },
        create() {
            this.$emit("c-create", true);
        },
    },
};
</script>
<style scoped>
.el-dropdown-menu__item > label {
    margin-bottom: 0.1rem !important;
}

.el-dropdown-menu {
    max-height: 720px !important;
    overflow-y: scroll;
}
.el-dropdown-menu__item,
.el-checkbox__label,
.el-checkbox {
    font-size: 12px !important;
}
</style>
