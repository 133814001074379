import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/servicesOfSetting",
        name: "servicesOfSetting",
        meta: {
            layout: "main",
            public: true,
        },
        component: () => import("@/views/settingsBlocks/services/index"),
    },
];
