export const rules = {
    type_name: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
    type_fullname: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
};
