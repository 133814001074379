import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import store from "@/store/index";
import { model } from "./properties/model";
import { i18n } from "@/utils/i18n";
import _ from 'lodash';

export const mutations = {
    SET_LIST: (state, data) => {
        if (!_.isEmpty(data)) {
            data.forEach(element => {
                let key = null;
                let mustChangeElem = {};
                let last_id = element.items.length - 1;
                if (!_.isEmpty(element.items)) {
                    element.items.forEach((item, index) => {
                        if (item.name == 'condition') {
                            key = index;
                            mustChangeElem = item;
                            delete element.items.splice(index, 1);
                        }
                        else {
                            if (index == 1) {
                                index = key;
                            }
                            else {
                                index = key + 1;
                            }
                        }
                    });
                    element.items[last_id] = mustChangeElem;
                } 
            });
        }
        state.list = data;
    },
    SET_LIST_EDIT: (state, data) => (state.list = data),
    SET_INVENTORY: (state, data) => (state.inventory = data),
	 EDIT_ITEMS: (state, data) => {
		state.model.id = data.id,
		state.model.license_requirement_id = data.license_requirement_id;
		state.model.equipment_type_id = data.equipment_type_id;

		if (data.items && _.isArray(data.items)) {
			data.items.forEach(item => {
				if (item.name == 'equipment_type') {
					state.model.until = item.interval ? item.interval.li_until : null;
				}
				else if (item.name == 'series_and_number'){
					state.model.series_status = item.li_status;
				}
				else if (item.name == 'passport') {
					state.model.passport = item.boolean ? item.boolean.li_type : false;
					state.model.passport_status = item.li_status;
				}
				else if (item.name == 'manual') {
					state.model.manual = item.boolean ? item.boolean.li_type : false;
					state.model.manual_status = item.li_status;
				}
				else if (item.name == 'document') {
					state.model.document = item.boolean ? item.boolean.li_type : false;
					state.model.document_status = item.li_status;
				}
				else if (item.name == 'document_number'){
					state.model.document_number_status = item.li_status;
				}
				else if (item.name == 'certificate') {
					state.model.certificate = item.boolean ? item.boolean.li_type : false;
					state.model.certificate_status = item.li_status;
				}
				else if (item.name == 'certificate_number'){
					state.model.certificate_number_status = item.li_status;
				}
				else if (item.name == 'property_ownership') {
					state.model.property_ownership_id = item.li_select ? item.li_select.source_id : null;
					state.model.property_ownership_status = item.li_status;
				}
				else if (item.name == 'condition') {
					state.model.condition_id = item.li_select ? item.li_select.source_id : null;
					state.model.condition_status = item.li_status;
				}
				else if (item.name == 'date_of_manufacture') {
					state.model.date_of_manufacture_status = item.li_status;
				}
			});
		}
	 },
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value;
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order;
    },
    SET_MODEL: (state, licenseRequirement) => {
        state.model = licenseRequirement;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },
    EMPTY_LIST_EDIT: (state) => {
        state.list = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
};
