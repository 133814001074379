<template>
    <div>
        <div class="home-doctors-info hidght445">
            <div class="card o-hidden">
                <div class="card-header home-d">
                    <h5 class="flex-1">Xodim turlari statistikasi</h5>
                    <el-button
                        icon="el-icon-rank"
                        class="mini-style2 mt10"
                        @click="centerDialogVisible = true"
                    >
                    </el-button>
                    <el-button
                        icon="el-icon-setting"
                        class="mini-style2 mt10"
                        @click="filterHome = true"
                    >
                    </el-button>
                </div>

                <div class="home-table">
                    <div id="chart">
                        <apexchart
                            ref="realtimeChart"
                            type="bar"
                            height="425"
                            :options="chartOptions"
                            :series="series"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <!-- end home-doctors-info -->

        <div class="my-modal">
            <el-drawer
                title="I am the title"
                :visible.sync="filterHome"
                :with-header="false"
                :append-to-body="true"
                size="40%"
                custom-class="my-modal-khan"
            >
                <FilterHomeDoctor></FilterHomeDoctor>
            </el-drawer>

            <div class="chart-si-modals">
                <el-dialog
                    :append-to-body="true"
                    title="XODIM TURLARI STATISTIKASI"
                    :visible.sync="centerDialogVisible"
                    width="97%"
                    custom-class="my-chart-si-modals"
                    center
                    @opened="dialogOpened('chartBigChild')"
                    @closed="dialogClosed('chartBigChild')">
                   <ChartBig dialog="chartBig" ref="chartBigChild"></ChartBig>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
import dialog from '../../utils/mixins/dialog';
import FilterHomeDoctor from "./components/filter";
import ChartBig from "./components/char-big.vue";

export default {
    props: ['types'],
    mixins: [dialog],
    components: {
        FilterHomeDoctor,
        ChartBig
    },
    watch:{
        $props: {   
            handler() {
                _.forEach(this.types, elem => {
                    this.chartOptions.xaxis.categories.push(elem.name);
                    this.series[0].data.push(elem.doctors_count);
               });
                this.series = [
                    {
                        data: this.series[0].data
                    }
                ]
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            filterHome: false,
            centerDialogVisible: false,
            series: [
                {
                    name: "Soni",
                    data: [],
            }],
            chartOptions: {
                chart: {
                    type: "bar",
                    height: 425,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                colors: ["#445686"],
                xaxis: {
                    categories: [],
                },
            },
        };
    },
};
</script>
