import {
    index,
    inventory,
    show,
    store,
    update,
    destroy,
    filteredDoctorsByClinic,
    downloadFile,
    remoteMethod,
    getDoctorsInTheClinic
} from "@/api/doctor/doctors";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then((res) => {
                    commit("SET_LIST", res.data.result.data.doctors);
                    commit("UPDATE_PAGINATION", {
                        key: "total",
                        value: res.data.result.data.pagination.total,
                    });
                    resolve(res.data.result.data.doctors);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params)
                .then((res) => {
                    commit("SET_INVENTORY", res.data.result.data.doctors);
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    remoteMethod({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            remoteMethod(params)
                .then((res) => {
                    resolve(res.data.result.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    getDoctorsInTheClinic({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getDoctorsInTheClinic(params)
                .then((res) => {
                    commit("SET_LIST", res.data.result.data.doctors);
                    commit("UPDATE_PAGINATION", {
                        key: "total",
                        value: res.data.result.data.pagination.total,
                    });
                    resolve(res.data.result.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then((res) => {
                    commit("SET_MODEL", res.data.result.data.doctor);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    filteredDoctorsByClinic({commit}, params){
        return new Promise((resolve, reject) => {
            filteredDoctorsByClinic(params).then((res) => {
                commit("SET_LIST", res.data.result.data.doctors);
                commit("UPDATE_PAGINATION", {
                    key: "total",
                    value: res.data.result.data.pagination.total,
                });
                resolve(res.data.result.data);
            }).catch((err) => {
                reject(err.resolve.data);
            });
        });
    },

    downloadFile({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            downloadFile(params).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve();
        });
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort);
    },

    updateFilter({ commit }, filter) {
        commit("SET_FILTER", JSON.parse(JSON.stringify(filter)));
    },

    updateColumn({ commit }, obj) {
        commit("UPDATE_COLUMN", obj);
    },
    updatePagination({ commit }, pagination) {
        commit("UPDATE_PAGINATION", pagination);
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve();
        });
    },
};
