export const filter = {
    id: null,
    clinic_id: null,
    filial_name: "",
    brand_name: "",
    prifilLink: "",
    created_at: "",
    updated_at: "",
    search: "",
};
