import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/condition",
        name: "condition",
        meta: {
            layout: "main",
            public: true
        },
        component: () => import("@/views/settingsBlocks/condition/index")
    }
];
