import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import store from '@/store/index'
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, data) => {              
        state.all_informations = data.all_informations;
    },
    SET_INVENTORY: (state, regions) => (state.inventory = regions),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, medicalEquipment) => {
        state.model.id = medicalEquipment.id;
        state.model.clinic_id = medicalEquipment.clinic_id;
        state.model.filial_id = medicalEquipment.filial_id;
        state.model.name = medicalEquipment.name;
        state.model.series_and_number = medicalEquipment.series_and_number;
        state.model.passport = medicalEquipment.passport ? 1 : (!medicalEquipment.passport ? 0 : null);
        state.model.date_of_manufacture = medicalEquipment.date_of_manufacture;
        state.model.manual = medicalEquipment.manual ? 1 : (!medicalEquipment.manual ? 0 : null);
        state.model.document = medicalEquipment.document ? 1 : (!medicalEquipment.document ? 0 : null);
        state.model.document_number = medicalEquipment.document_number;
        state.model.document_date = medicalEquipment.document_date;
        state.model.certificate = medicalEquipment.certificate ? 1 : (!medicalEquipment.certificate ? 0 : null);
        state.model.certificate_number = medicalEquipment.certificate_number;
        state.model.certificate_date = medicalEquipment.certificate_date;
        state.model.property_ownership_id = medicalEquipment.property_ownership_id;
        state.model.condition_id = medicalEquipment.condition_id;
        state.model.how_long_use_it = medicalEquipment.how_long_use_it;
        state.model.created_at = medicalEquipment.created_at;
        state.model.updated_at = medicalEquipment.updated_at;

        state.old_anothers = [];
        if (medicalEquipment.another_certificates && _.isArray(medicalEquipment.another_certificates)) {
            medicalEquipment.another_certificates.forEach(item => {
                state.old_anothers.push({
                    id: item.id,
                    medical_technique_id: item.medical_technique_id,
                    name: item.name,
                    // images => later add
                });
            });

            state.old_anothers = _.sortBy(state.old_anothers, ['id']);
        }

    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
    },
    EMPTY_LIST: (state) => {
        state.all_informations = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    }
};
