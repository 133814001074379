import { ru_messages } from '@/lang/ru/messages';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'ru',
    messages: {
        ru: {
            message: ru_messages
        }
    },
    silentTranslationWarn: true
});

//export default i18n;