<template>
    <div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="8">
                <Application :sum_chart.sync="chartData"></Application>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="16">
                <ApplicationRight @run-fetch-data="fetchData" :chart_data.sync="chartData"></ApplicationRight>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import Application from "./application.vue";
import ApplicationRight from "./applicationRight.vue";
export default {
    name: "Home",
    components: {
        Application,
        ApplicationRight,
    },
    created(){},
    data() {
        return {
            chartData: [],
            sumChart: []
        }
    },
    watch:{

    },
    methods: {
        ...mapActions({
            editChart: 'dashboard/chart_statistical_app'
        }),
        fetchData(type){
            this.editChart(type).then((res) => {
                if (res.chart_data) {
                    this.chartData = res.chart_data;
                }
            }).catch((err) => {
                console.log('err', err);
            });
        }
    }

};
</script>
