<template>
    <div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
                <LeftInfo :techniques="techniques"></LeftInfo>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
                <RightInfo :statistics="statistics"></RightInfo>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import _ from 'lodash';
import LeftInfo from "./left.vue";
import RightInfo from "./right.vue";
export default {
    name: "homeTechnique",
    components: {
        LeftInfo,
        RightInfo,
    },
    created(){
        let debounce_func = _.debounce(this.load, 800);
        debounce_func();
    },
    data(){
        return {
            loadingData: false,
            techniques: [],
            statistics: []
        }
    },
    methods:{
        ...mapActions({
            getLastTechniques: 'dashboard/getLastTechniques'
        }),
        load(){
            if (!this.loadingData){
                this.getLastTechniques().then((res) => {
                    if (res.data) {
                        this.techniques = res.data.techniques;
                        this.statistics = res.data.statistic_of_techniques;    
                    }
                }).catch((err) => {
                    console.log(err)
                });
            }
        }
    }
};
</script>
