import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/licenseRequirementTechniques",
        method: "get",
        params: params,
    });
}

export function inventory(params) {
    return request({
        url: "/licenseRequirementTechniques/inventory",
        method: "get",
        params,
    });
}

export function show(id) {
    return request({
        url: `/licenseRequirementTechniques/${id}`,
        method: "get",
    });
}

export function editItems(id) {
	return request({
		 url: `/licenseRequirementTechniques/editItems/${id}`,
		 method: "get",
	});
}

export function store(data) {
    return request({
        url: "/licenseRequirementTechniques",
        method: "post",
        data,
    });
}

export function update(data) {
    return request({
        url: `/licenseRequirementTechniques/${data.id}`,
        method: "put",
        data,
    });
}

export function destroy(id) {
    return request({
        url: `/licenseRequirementTechniques/${id}`,
        method: "delete",
    });
}
