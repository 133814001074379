export const filter = {
    id: null,
    application_type: null,
    cabinet_type: null,
    review_time_id: null,
    filter_condition_id: null,
    start_date: null,
    end_date: null,
    region_id: null,
    city_district_id: null
};
