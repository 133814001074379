<template>
    <div>
        <div class="home-doctors-info">
            <div class="card o-hidden">
                <div class="card-header home-d">
                    <h5 class="flex-1">
                        Arizalar holati bo’yicha statistik ko’rsatkichlar
                    </h5>
                </div>

                <div class="home-table">
                    <div id="chart">
                        <apexchart
                            type="radialBar"
                            height="490"
                            :options="chartOptions"
                            :series="series"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <!-- end home-doctors-info -->
    </div>
</template>
<script>
export default {
    props: {
        sum_chart: {
            type: Array,
            required: true
        }
    },
    components: {},
    watch: {
        $props: {
            handler() {
                let sum_new = 0, sum_review = 0, sum_succeed = 0, sum_returned = 0, sum_rejected = 0;
                _.forEach(this.sum_chart, item => {
                    sum_new += item.count_new;
                    sum_review += item.count_review;
                    sum_succeed += item.count_succeed;
                    sum_returned += item.count_returned;
                    sum_rejected += item.count_rejected;
                });
                this.series = [sum_new, sum_review, sum_succeed, sum_returned, sum_rejected];
            },  
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            radio2: "1 Y",
            filterHome: false,
            series: [],
            chartOptions: {
                chart: {
                    height: 490,
                    type: "radialBar",
                },
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: "30%",
                            background: "transparent",
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: false,
                            },
                        },
                    },
                },
                colors: ["#1ab7ea", "#0084ff", "#39539E", "#feb019", "#ff6178"],
                labels: [
                    "Kelib tushgan",
                    "Ko'rib chiqilmoqda",
                    "Tasdiqlangan ",
                    "Qaytarilgan",
                    "Bekor qilingan",
                ],
                legend: {
                    show: true,
                    floating: true,
                    fontSize: "16px",
                    position: "left",
                    offsetX: 0,
                    offsetY: 15,
                    labels: {
                        useSeriesColors: true,
                    },
                    markers: {
                        size: 0,
                    },
                    formatter: function (seriesName, opts) {
                        return (
                            seriesName +
                            ":  " +
                            opts.w.globals.series[opts.seriesIndex]
                        );
                    },
                    itemMargin: {
                        vertical: 3,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                show: false,
                            },
                        },
                    },
                ],
            },
        };
    },
};
</script>
