export const rules = {
    legal_entity_name: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "change",
        },
    ],
    brand_name: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "change",
        },
    ],
    email: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "change",
        },
    ],
    phone_number: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "change",
        },
    ],
    region_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
        },
    ],
    city_district_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
        },
    ],
    type_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "blur",
        },
    ],
};
