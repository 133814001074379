import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";
import { i18n } from "@/utils/i18n";

export const mutations = {
    SET_LIST: (state, doctors) => (state.list = doctors),
    SET_INVENTORY: (state, doctors) => (state.inventory = doctors),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value;
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order;
    },
    SET_MODEL: (state, doctor) => {
        state.model.id = doctor.id;
        state.model.user_id = doctor.user_id;
        state.model.clinic = doctor.clinic;
        if (doctor.clinic.parent) {
            state.model.clinic_id = doctor.clinic.parent.id;
            state.model.filial_id = doctor.clinic_id;
        }
        else{
            state.model.clinic_id = doctor.clinic_id;
            state.model.filial_id = null;
        }
        state.model.name = doctor.name;
        state.model.surname = doctor.surname;
        state.model.lastname = doctor.lastname;
        state.model.id_number = doctor.id_number;
        state.model.passport_series_and_number = doctor.passport_series_and_number;
        state.model.brith_date = doctor.brith_date;
        state.model.stir = doctor.stir;
        state.model.inn = doctor.inn;
        state.model.email = doctor.email;
        state.model.work_tel_number = doctor.work_tel_number;
        state.model.mobile_tel_number = doctor.mobile_tel_number;
        state.model.gender = doctor.gender;
        state.model.nation_id = doctor.nation_id;
        state.model.place_of_brith = doctor.place_of_brith;
        state.model.region = doctor.region;
        state.model.region_id = doctor.region_id;
        state.model.city_district = doctor.city_district;
        state.model.city_district_id = doctor.city_district_id;
        state.model.neighborhood = doctor.neighborhood;
        state.model.home = doctor.home;
        state.model.apartment_number = doctor.apartment_number;
        state.model.image = doctor.images[0];
        state.model.typesOfEmployee = doctor.typesOfEmployee;
        state.model.types_of_employee_id = doctor.types_of_employee_id;
        state.model.direction = doctor.direction;
        state.model.direction_id  = doctor.direction_id;
        state.model.created_at = doctor.created_at;
        state.model.updated_at = doctor.updated_at;
        
        /** Set Old Doctor Information */
        state.informations = [];
        if (doctor.doctor_informations && _.isArray(doctor.doctor_informations)) {
            doctor.doctor_informations.forEach(elem => {
                state.informations.push({
                    id: elem.id,
                    doctor_id: elem.doctor_id,
                    higher_education_institution: elem.higher_education_institution,
                    higher_education_institution_id: elem.higher_education_institution_id,
                    specialization: elem.specialization,
                    direction: elem.direction,
                    when_it_started: elem.when_it_started,
                    diploma_serial_number: elem.diploma_serial_number,
						  date_of_give_diploma: elem.date_of_give_diploma,
                    application_serial_number: elem.application_serial_number,
                    files: elem.files,
                    fayls: []
                });
            });
        }
        
        /** Set Old Doctor Work Experiences */
        state.work_experiences = [];
        if (doctor.doctor_work_experiences && _.isArray(doctor.doctor_work_experiences)) {
            doctor.doctor_work_experiences.forEach(work => {
                state.work_experiences.push({
                    id: work.id,
                    doctor_id: work.doctor_id,
                    work_experience: work.work_experience ? 1 : ( !work.work_experience ? 0 : null ),
                    when_finished: work.when_finished,
                    how_many_years: work.how_many_years,
                    qualification_category: work.qualification_category,
                    qualification_category_id: work.qualification_category_id,
                    received: work.received,
                    direction: work.direction,
                    when_received: work.when_received,
                    what_documents: work.what_documents,
                    document_serial_number: work.document_serial_number,
                    document_date: work.document_date,
                    name_organization: work.name_organization,
                    person_who_signed: work.person_who_signed,
                    files: work.files,
                    fayls: []
                });
            });
        }

        /** Set Old Doctor Certificates */
        state.certificates = [];
        if (doctor.doctor_certificates && _.isArray(doctor.doctor_certificates)) {
            doctor.doctor_certificates.forEach(certificate => {
                state.certificates.push({
                    id: certificate.id,
                    doctor_id: certificate.doctor_id,
                    name: certificate.name,
                    which_specialization: certificate.which_specialization,
                    direction: certificate.direction,
                    when_received: certificate.when_received,
                    what_documents: certificate.what_documents,
                    certificate_serial_number: certificate.certificate_serial_number,
                    certificate_date: certificate.certificate_date,
                    name_organization: certificate.name_organization,
                    person_who_signed: certificate.person_who_signed,
                    files: certificate.files,
                    fayls: []
                });
            });
        }

        /** Set Old Doctor Academic Degrees */
        state.academic_degrees = [];
        if (doctor.doctor_academic_degrees && _.isArray(doctor.doctor_academic_degrees)) {
            doctor.doctor_academic_degrees.forEach(degree => {
                state.academic_degrees.push({
                    id: degree.id,
                    doctor_id: degree.doctor_id,
                    academic_degree_id: degree.academic_degree_id,
                    academic_degree: degree.academic_degree,
                    where_taken: degree.where_taken,
                    documents_signed: degree.documents_signed,
                    academic_degree_serial_number: degree.academic_degree_serial_number,
                    files: degree.files,
                    fayls: []
                });
            });
        }

        /** Set Old Doctor Rank */
        state.rank = {};
        if (doctor.doctor_rank && _.isObject(doctor.doctor_rank)) {
            state.rank.id = doctor.doctor_rank.id;
            state.rank.doctor_id = doctor.doctor_rank.doctor_id;
            state.rank.command_number = doctor.doctor_rank.command_number;
            state.rank.person_who_signed = doctor.doctor_rank.person_who_signed;
            state.rank.person_rank = doctor.doctor_rank.person_rank;
            state.rank.command_date = doctor.doctor_rank.command_date;
            state.rank.command_serial_number = doctor.doctor_rank.command_serial_number;
            state.rank.rank_items = _.map(doctor.doctor_rank.rank_items, function (elem) {
                return {
                    id: elem.id,
                    doctor_rank_id: elem.doctor_rank_id,
                    in_management: elem.in_management ? 1 : (!elem.in_management ? 0 : null),
                    organization: elem.organization,
                    rank: elem.rank
                }
            });
        }
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
};
