<template>
    <div class="home-doctors-info hidght445" v-loading="loadingData">
        <div class="card o-hidden">
            <div class="home-table">
                <div id="chart">
                    <apexchart
                        ref="realtimeChart"
                        type="bar"
                        height="600"
                        :options="chartOptions"
                        :series="series"
                    ></apexchart>
                </div>
            </div>
               <div class="my-pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
export default {
    props:['dialog'],
    computed: {
        ...mapGetters({
            directions: "medicalDirections/directions",
            pagination: "medicalDirections/pagination"
        })
    },
    watch:{
       'pagination.page': {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.fetchData)) {
                    this.fetchData();
                }
            },
            deep: true,
            // immediate: true,
        },
        'pagination.per_page': {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.fetchData)) { 
                    this.fetchData();
                }
            },
            deep: true,
            // immediate: true,
        },
    },
    data() {
        return {
            loadingData: false,
            series: [{
            name: "Soni",
            data: [],
        }],
        chartOptions: {
            chart: {
                type: "bar",
                height: 425,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#445686"],
            xaxis: {
                categories: [],
            },
        },
        };
    },
    methods: {
        ...mapActions({
            getAllDirections: "medicalDirections/getAllDirections",
            updatePagination: "medicalDirections/updatePagination"
        }),
        fetchData(){
            if (!this.loadingData) {                
                this.loadingData = true;
                const query = {...this.pagination};
                this.getAllDirections(query)
                .then(() => {
                    this.loadingData = false; 
                    if (this.directions.length != 0) {
                        this.series[0].data = [];
                        this.chartOptions.xaxis.categories = [];
                        let items_of_series = [], items_of_categories = [];

                        for (let key in this.directions){
                            if (this.directions.hasOwnProperty(key)) {
                                let chart_data = this.directions[key];
                                items_of_series.push({x: chart_data.name, y: chart_data.doctors_count});
                                items_of_categories.push(chart_data.name);
                            }
                        }
                         
                        this.series = [
                            {data: items_of_series}
                        ];

                        this.chartOptions.xaxis = {
                            categories: items_of_categories
                        }
                    }
                })
                .catch((err) => {this.loadingData = false;});
            }
        },
        opened() {
            this.fetchData();
        },
        closed(){
            this.$store.commit('medicalDirections/EMPTY_DIRECTIONS');
            this.updatePagination({
                key: "page",
                value: 1
            });
        }
    },
};
</script>
