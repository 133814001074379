export const filter = {
  id: null,
  clinic_id: null,
  filial_id: null,
  region_id: null,
  name: '',
  series_and_number: '',
  date_of_manufacture: '',
  document_number: '',
  created_at: '',
  updated_at: '',
  search: ''  
};
