import { i18n } from "@/utils/i18n";

export const columns = {
    id: {
        show: true,
        title: i18n.t("message.n"),
        sortable: true,
        column: "id",
    },
    name: {
        show: true,
        title: i18n.t("message.name2"),
        sortable: true,
        column: "name",
    },
    filial: {
        show: true,
        title: i18n.t("message.filials"),
        sortable: true,
        column: "filial",
    },
    date_of_manufacture: {
        show: true,
        title: i18n.t("message.date_of_manufacture"),
        sortable: true,
        column: "date_of_manufacture",
    },
    series_and_number: {
        show: true,
        title: i18n.t("message.series_and_number"),
        sortable: true,
        column: "series_and_number",
    },
    document_number: {
        show: true,
        title: i18n.t("message.document_number"),
        sortable: true,
        column: "document_number",
    },
    document_date: {
        show: false,
        title: i18n.t("message.document_date"),
        sortable: true,
        column: "document_date",
    },
    created_at: {
        show: true,
        title: i18n.t("message.created_at"),
        sortable: true,
        column: "created_at",
    },
    updated_at: {
        show: false,
        title: i18n.t("message.updated_at"),
        sortable: true,
        column: "updated_at",
    },
    settings: {
        show: true,
        title: i18n.t("message.settings"),
        sortable: false,
        column: "settings",
    },
};
