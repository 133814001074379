<template>
    <div id="app">
        <component :is="layout">
            <router-view />
        </component>
    </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";

export default {
    computed: {
        layout() {
            return (this.$route.meta.layout || "empty") + "-layout";
        },
    },
    components: {
        EmptyLayout,
        MainLayout,
    },
};
</script>
<style>
    
    @media only screen and (max-width: 1480px) {
        .table-my-code thead th,
        .table-my-code tbody td {
            font-size: 11px !important;
        }
    }
    @media only screen and (max-width: 1680px) {
        .table-my-code thead th,
        .table-my-code tbody td {
            font-size: 12px !important;
        }
    }   
    .info-click .router-link-exact-active.router-link-active{
        background-color: rgba(0,135,175,.1019607843);
        color: #0087af;
    }
    .info-click .top-c-mue ul li a{
        padding: 15px 30px;
    }
    .info-click .top-c-mue ul{
        padding: 0px;
    }

</style>