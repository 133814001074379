export const ru_messages = {
    n: "№",
    name: "Nomi",
    name2: "Nomi",
    name3: "I.F.O",
    type_fullname: "To'liq nomi",
    nomi: "Nomi",
    created_at: "Sana",
    updated_at: "Oʻzgartirilgan sana",
    login: "Авторизоваться",
    permission: "Разрешения",
    settings: "Настройки",
    region: "Viloyat",
    regions: "Viloyat",
    new_c: "Qo'shish",
    new_u: "O'zgartirish",
    documentType: "Hujjat Turi",
    settings: "Sozlama...",
    typesOfEmployees: "Xodimlar turlari",
    direction: "Xodimlar yo'nalishi",
    medicalOrganizationTypes: "Tibbiy tashkilot turi",
    resultsOfTheApplications: "Murojjat natijalari",
    qualificationCategories: "Malaka toifasi ",
    licenseProcedures: "Litsenziya tartiblari ixtisosligi",
    citizenships: "Fuqarolik",
    country: "Davlat",
    nations: "Millati",
    higherEducations: "Oliy o'quv yutlari",
    currencyTypes: "Valyuta turi",
    propertyOwnerships: "Mulk Egaligi",
    conditions: "Holati ",
    qualificationLevels: "Malaka darajasi",
    cityDistricts: "Shahar va Tuman ",
    cityDistrict: "Shahar va Tuman ",
    myNameClick: "Yuridik shaxsning nomi",
    brand_name: "Brend nomi",
    inn: "INN",
    email: "Email",
    address: "Manzil",
    r_s_bank: "R/S BANK",
    mfo: "MFO",
    cadastre_number: "Kadastr raqami",
    document_name: "Huquqiy hujjat nomi",
    document_date: "Huquqiy hujjat sanasi",
    document_number: "Huquqiy hujjat raqami",
    clinics: " Tibbiy muassasalar",
    profilLink: "Profil",
    roles: "Rol",
    users: "Foydalanuvchi",
    username: "Ism",
    surname: "Familiya",
    first_name: "Otasni ismi ",
    tel_number: "Telefon raqam",
    password: "Porol",
    role_id: "Rol",
    email: "Email",
    identification_code: "Kod",
    gender: "Jinsi",
    status: "Holat",
    male: "Erkak",
    female: "Ayol",
    filials: "Filial",
    passport_series_and_number: "Pasport  raqami",
    date_of_manufacture: "Ishlab chiqarilgan sanasi",
    series_and_number:"Seriya va raqami",
    academic_degree: "Ilmiy Darajasi",
    doctor:"Xodimlar",
    document_type: 'Тип документа',
    file_name: 'Имя файла',
    view: 'Просмотр',
    close: 'Закрыть',
    information: "Bino inshoat ma'lumotlari",
    technique: "Texnika ma'lumotlari",
    equipment: "Uskuna ma'lumotlari",
    name: 'Наименование',
    technique_type: 'Texnika turi',
    equipment_type: 'Uskuna turi',
    'The image format must be': 'Формат изображения должен быть JPEG, JPG, PNG, PDF, DOC, DOCX, XLS, XLSX',
    medical_directions: "Tibbiy yo'nalishlar ",
    state_clinic: 'Davlat klinika',
    private_clinic: 'Xususiy klinika',
    organizational_form:"Kompaniyaning tashkiliy shakli",
    medicalTechnique: "Tibbiy texnika",
    medicalEquipment: "Tibbiy Uskuna",
    code: 'Kod',
    lifetime: 'Amal qilish muddati',
    services: 'Xizmatlar',
    parent: "Родитель",
    count_services: "Tibbiy xizmat soni",
    name_of_clinic: "Tibbiy muassasa nomi",
    regional_setting: "Xududiy sozlama",
    licensing_requirements: 'Litsenziya tartiblari ixtisosligi',
    must_be_included_requirments: "Majburiy ravishda qo'shiladigan ixtisosligi",
    licenseProcedureConnections: "Litsenziya talablar bog'lash",
    statement_type: 'Ariza turi',
    'Obtaining a license based on requirements': 'Litsenziya talablar asosida olish',
    time_of_registered: "Ro'yxatdan o'tgan vaqti",
    license_requirements: 'Litsenziya tartiblari',
    license: 'Litsenziya',
    foyiz: '%',
    organization: 'Tashkilot',
    application_number: 'Ariza raqami',
    cabinet_type: 'Kabinet turi',
    application_created_at: "Ariza sanasi",
    bank_name: "Bank nomi",
    deal_number: "Shartnoma raqami"
};
