import Layout from '@/layouts/EmptyLayout'
export default [{
  path: '/medicalEquipments',
  name: 'medicalEquipments',
  meta: {
    layout: 'main',
    public: true
  },
  component: () => import('@/views/medicalEquipments/index')
}]
