<template>
    <div class="home-doctors-info mb-5" v-loading="loadingData">
        <div class="card o-hidden">
            <h5 class="flex-1 scrol-title">Tibbiy muassasalar</h5>
            <div class="slider-scrol">
                <div class="slide">
                    <div class="itme-slider" v-for="(item, index) in clinics" :key="index">
                        <router-link :to="{ path: '/officeClinic/infoClinic/' + item.id }">
                            <div v-if="item.image['id']">
                                <img :src="appURL + item.image.path" />
                            </div>
                            <div v-else>
                                <img src="./../../../public/img/avatar_clinica.jpg" />
                            </div>
                            <div class="itme-slider-name">
                                {{ item.legal_entity_name ? item.legal_entity_name : '' }}
                            </div>
                            <div class="itme-slider-y">{{ item.brand_name ? item.brand_name : '' }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import _ from 'lodash';
export default {
    data() {
        return {
            appURL: process.env.VUE_APP_URL_DOCS,
            loadingData: false
        };
    },
    computed: {
        ...mapGetters({
            list: 'dashboard/list'
        }),
        clinics(){
            let items = []
            if (this.list && _.size(this.list)) {
                this.list.forEach(elem => {
                    let image = {};
                    if (_.size(elem.images)) {
                        image['id'] = elem.images[0].id;
                        image['path'] = elem.images[0].path;
                    }else{
                        image['id'] = null;
                        image['path'] = null;
                    }
                    items.push({
                        id: elem.id,
                        legal_entity_name: elem.legal_entity_name,
                        brand_name: elem.brand_name,
                        image: image
                    });
                    image = {};
                });
                return items;
            }
            return items;
        }
    },
    created(){
        let debounce_func = _.debounce(this.load, 500);
        debounce_func();
    },
    methods: {
        ...mapActions({
            getLastClinics: 'dashboard/getLastClinics'
        }),
        load(){
            if(!this.loadingData){
                this.loadingData = true;
                this.getLastClinics().then(() => {
                    this.loadingData = false;
                }).catch((err) => {
                    this.loadingData = false;
                    console.log(err);    
                });
            }
        }
    },
};
</script>
<style>
</style>
