import Layout from '@/layouts/EmptyLayout'
export default [{
  path: '/applications',
  name: 'applications',
  redirect: "/applications/allApplications",
  meta: {
    layout: 'main',
    public: true
  },
  children: [
    {
        path: "/applications/allApplications",
        name: "allApplications",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/applications/allApplications/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/applications/processFillingApplications",
        name: "processFillingApplications",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/applications/processFillingApplications/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/applications/newApplications",
        name: "newApplications",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/applications/newApplications/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/applications/processCorrection",
        name: "processCorrection",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/applications/processCorrection/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/applications/underReview",
        name: "underReview",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/applications/underReview/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/applications/incomplete",
        name: "incomplete",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/applications/incomplete/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/applications/processApproval",
        name: "processApproval",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/applications/processApproval/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/applications/rejected",
        name: "rejected",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/applications/rejected/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
      path: "/applications/timeRunningOut",
      name: "timeRunningOut",
      meta: {
          layout: "main",
      },
      component: () => import("@/views/applications/timeRunningOut/index"),
      // meta: { title: i18n.t('message.user') }
    },
    {
      path: "/applications/success",
      name: "success",
      meta: {
          layout: "main",
      },
      component: () => import("@/views/applications/success/index"),
      // meta: { title: i18n.t('message.user') }
    },
    {
      path: "/applications/negotiationProcess",
      name: "negotiationProcess",
      meta: {
          layout: "main",
      },
      component: () => import("@/views/applications/negotiationProcess/index"),
      // meta: { title: i18n.t('message.user') }
    },
    
  ],
  component: () => import('@/views/applications/index')
}]
