<template>
    <div>
        <div class="home-doctors-info">
            <div class="card o-hidden">
                <div class="card-header home-d">
                    <h5 class="flex-1">Tibbiy xizmat turlari diagrammasi</h5>
                </div>

                <div class="home-table">
                    <div id="chart">
                        <apexchart
                            type="pie"
                            width="400"
                            :options="chartOptions"
                            :series="series"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <!-- end home-doctors-info -->
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            radio2: "1 Y",
            filterHome: false,
            series: [44, 55, 13, 43, 22],
            chartOptions: {
                chart: {
                    width: 530,
                    type: "pie",
                },
                labels: [
                    "Ambulator",
                    "Statsionar",
                    "TeamTeamTeam C",
                    "Team D",
                    "Team E",
                ],
                legend: {
                    show: false,
                },
                theme: {
                    monochrome: {
                        enabled: true,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            series: [44, 55, 13, 43, 22],
            chartOptions: {
                chart: {
                    width: 480,
                    type: "pie",
                },
                labels: [
                    "Ambulator",
                    "Statsiyanar",
                    "TeamTeamTeam C",
                    "Team D",
                    "Team E",
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
        };
    },
};
</script>
