<template>
    <div>
        <el-input
            :value="value"
            @input="dispatch"
            :type="type"
            :placeholder="placeholder"
            :prefix-icon="icon"
            :size="size"
            :class="className"
            v-mask="getMaskFormat"
            clearable
            :disabled="disabled"
        >
        </el-input>
    </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
export default {
    name: "user_status",
    props: {
        size: {
            default: "large",
        },
        placeholder: {
            default: "Поиск",
        },
        type: {
            default: "text",
        },
        className: {
            default: "w-100",
        },
        icon: {
            default: null,
        },
        inputValue: {
            default: "",
        },
        maskFormat: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: null,
        };
    },
    computed: {
        getMaskFormat: function () {
            if (this.maskFormat == "tel") {
                return "+998#########";
            }
            if (this.maskFormat == "passport") {
                return 'AA#######';
            }
            if (this.maskFormat == "inn") {
                return "#########";
            }
            if (this.maskFormat == "stir") {
                return "#########";
            }
            if (this.maskFormat == "mfo") {
                return "#####";
            }
            if (this.maskFormat == "r_s_bank") {
                return "####################";
            }
            if (this.maskFormat == "card") {
                return "9999-9999-9999-9999";
            }
            if (this.maskFormat == "card_expiry_date") {
                return "99/99";
            }
            return this.maskFormat;
        },
    },
    watch: {
        inputValue: {
            handler: function () {
                this.value = this.inputValue;
            },
            immediate: true,
        },
        maskFormat: {
            handler: function () {},
            immediate: true,
            deep: true,
        },
    },
    methods: {
        dispatch(e) {
            this.$emit("input", e);
            this.value = e;
        },
    },
};
</script>
