export const filter = {
    id: null,
    legal_entity_name: "",
    brand_name: "",
    type_id: null,
    inn: "",
    email: "",
    region_id: null,
    city_district_id: null,
    address: "",
    clinic_type: null,
    created_at: "",
    updated_at: "", 
};
