import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import store from '@/store/index'
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, medicalTechniques) => {              
        state.list = medicalTechniques;        
    },
    SET_INVENTORY: (state, regions) => (state.inventory = regions),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, medicalTechnique) => {
        state.model.id = medicalTechnique.id;
        state.model.clinic_id = medicalTechnique.clinic_id;
        state.model.filial_id = medicalTechnique.filial_id;
        state.model.name = medicalTechnique.name;
        state.model.series_and_number = medicalTechnique.series_and_number;
        state.model.passport = medicalTechnique.passport ? 1 : (!medicalTechnique.passport ? 0 : null);
        state.model.date_of_manufacture = medicalTechnique.date_of_manufacture;
        state.model.manual = medicalTechnique.manual ? 1 : (!medicalTechnique.manual ? 0 : null);
        state.model.document = medicalTechnique.document ? 1 : (!medicalTechnique.document ? 0 : null);
        state.model.document_number = medicalTechnique.document_number;
        state.model.document_date = medicalTechnique.document_date;
        state.model.certificate = medicalTechnique.certificate ? 1 : (!medicalTechnique.certificate ? 0 : null);
        state.model.certificate_number = medicalTechnique.certificate_number;
        state.model.certificate_date = medicalTechnique.certificate_date;
        state.model.property_ownership_id = medicalTechnique.property_ownership_id;
        state.model.condition_id = medicalTechnique.condition_id;
        state.model.how_long_use_it = medicalTechnique.how_long_use_it;
        state.model.created_at = medicalTechnique.created_at;
        state.model.updated_at = medicalTechnique.updated_at;

        state.old_anothers = [];
        if (medicalTechnique.another_certificates && _.isArray(medicalTechnique.another_certificates)) {
            medicalTechnique.another_certificates.forEach(item => {
                state.old_anothers.push({
                    id: item.id,
                    medical_technique_id: item.medical_technique_id,
                    name: item.name,
                    // images => later add
                });
            });

            state.old_anothers = _.sortBy(state.old_anothers, ['id']);
        }

    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    }
};
