import Layout from '@/layouts/EmptyLayout'
export default [{
  path: '/doctorCardInfo/:id',
  name: 'doctorCardInfo',
  meta: {
    layout: 'main',
    public: true
  },
  component: () => import('@/views/doctorCardInfo/index')
}]
