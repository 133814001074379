<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="8" :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                <Application :report="state_data"></Application>
            </el-col>
             <el-col :span="8" :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                <ChartDiagrammCenter :own_data="own_data"></ChartDiagrammCenter>
            </el-col>
            <!--  <el-col :span="8" :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                <ChartDiagrammRight></ChartDiagrammRight>
            </el-col> -->
        </el-row>
    </div>
</template>

<script>
import _ from 'lodash'; 
import { mapActions } from 'vuex';
import Application from "./application.vue";
import ChartDiagrammCenter from "./chart_diagramm_center";
import ChartDiagrammRight from "./chart_diagramm_right";
export default {
    name: "HomeCategory",
    components: {
        Application,
        ChartDiagrammCenter,
        ChartDiagrammRight
    },
    data(){
        return{
            loadingData: false,
            own_data: [],
            state_data: []
        }
    },
    created(){
        let debounce_fun = _.debounce(this.fetchData, 500);
        debounce_fun();
    },
    methods:{
        ...mapActions({
            reportForServicesOfClinics: "dashboard/reportForServicesOfClinics"
        }),
        fetchData(){
            if (!this.loadingData) {
                this.reportForServicesOfClinics().then((res) => {
                    this.state_data = res.report_for_chart;
                    this.own_data = res.report_for_chart;
                }).catch((err) => {
                    console.log(err);
                })
            }
        }   
    }
};
</script>
