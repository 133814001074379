export const rules = {
    clinic_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    name: [
        { required: true, message: 'Пожалуйста, введите название ', blur: 'change' },
    ],
    equipment_type_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
};
