export const model = {
    id: null,
    name: "",
    surname: "",
    first_name: "",
    password: "",
    tel_number: "",
    role_id: null,
    region_id: null,
    is_employee: null,
    email: "",
    identification_code: "",
    gender: "",
    status: "active",
    verified: false,
    locked: false,
    created_at: "",
    updated_at: "",
};
