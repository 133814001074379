
export const getters = {
  list: state => state.list,
  inventory: state => state.inventory,
  filtered_filial: state => state.filtered_filial, 
  documents: state => state.documents, 
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort,
  items: state => state.items,
  building_informations_of_clinics: state => state.building_informations_of_clinics,
};
