export const rules = {
    citizenship_name: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
    country: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
};
