import {
    getDoctors,
    getLastClinics,
    getLastTechniques,
    getLastEquipments,
    getTypes,
    getAllTypes,
    reportForServicesOfClinics,
    diynamic_of_licenses,
    chart_statistical_app
} from "@/api/dashboard/dashboard";

export const actions = {

    chart_statistical_app({ commit }, $type) {
        return new Promise((resolve, reject) => {
            chart_statistical_app({type: $type})
                .then((res) => {
                    resolve(res.data.result.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    diynamic_of_licenses({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            diynamic_of_licenses(params)
                .then((res) => {
                    resolve(res.data.result);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    
    reportForServicesOfClinics({ commit }) {
        return new Promise((resolve, reject) => {
            reportForServicesOfClinics()
                .then((res) => {
                    resolve(res.data.result.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    getAllTypes({ commit }) {
        return new Promise((resolve, reject) => {
            getAllTypes()
                .then((res) => {
                    resolve(res.data.result.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    getTypes({ commit }) {
        return new Promise((resolve, reject) => {
            getTypes()
                .then((res) => {
                    resolve(res.data.result);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
 
    getDoctors({ commit }) {
        return new Promise((resolve, reject) => {
            getDoctors()
                .then((res) => {
                    commit('SET_DOCTORS', res.data.result); //.data.doctors
                    resolve(res.data.result);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    getLastClinics({ commit }) {
        return new Promise((resolve, reject) => {
            getLastClinics()
                .then((res) => {
                    commit('SET_LIST', res.data.result.data.clinics);
                    resolve(res.data.result);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    getLastTechniques({ commit }) {
        return new Promise((resolve, reject) => {
            getLastTechniques()
                .then((res) => {
                    resolve(res.data.result);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    getLastEquipments({ commit }) {
        return new Promise((resolve, reject) => {
            getLastEquipments()
                .then((res) => {
                    resolve(res.data.result);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort);
    },

    updateFilter({ commit }, filter) {
        commit("SET_FILTER", JSON.parse(JSON.stringify(filter)));
    },

    updateColumn({ commit }, obj) {
        commit("UPDATE_COLUMN", obj);
    },
    updatePagination({ commit }, pagination) {
        commit("UPDATE_PAGINATION", pagination);
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve();
        });
    },
};
