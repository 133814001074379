import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/licenseSendApplications",
        method: "get",
        params: params,
    });
}

export function getConfirmedApplications(params) {
    return request({
        url: "/licenseSendApplications/getConfirmedApplications",
        method: "get",
        params: params,
    });
}

export function getApplicationResults(params) {
    return request({
        url: "/licenseSendApplications/getApplicationResults",
        method: "get",
        params: params,
    });
}

export function show(id) {
    return request({
        url: `/licenseSendApplications/${id}`,
        method: "get",
    });
}

export function store(data) {
    return request({
        url: "/licenseSendApplications",
        method: "post",
        data,
    });
}

export function update(data) {
    return request({
        url: `/licenseSendApplications/${data.id}`,
        method: "put",
        data,
    });
}

export function destroy(id) {
    return request({
        url: `/licenseSendApplications/${id}`,
        method: "delete",
    });
}

export function sendApplicationConfirm(id) {
    return request({
        url: `/licenseSendApplications/sendApplicationConfirm/${id}`,
        method: "get",
    });
}

export function getSpecializations(id) {
    return request({
        url: `/licenseSendApplications/getSpecializations/${id}`,
        method: "get",
    });
}
