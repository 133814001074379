import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/doctorTable",
        name: "doctorTable",
        meta: {
            layout: "main",
            public: true,
        },
        component: () => import("@/views/doctorTable/index"),
    },
];
