import { i18n } from "@/utils/i18n";

export const columns = {
    id: {
        show: true,
        title: i18n.t("message.n"),
        sortable: true,
        column: "id",
    },
    legal_entity_name: {
        show: true,
        title: i18n.t("message.name2"),
        sortable: true,
        column: "legal_entity_name",
    },
    brand_name: {
        show: true,
        title: i18n.t("message.brand_name"),
        sortable: true,
        column: "brand_name",
    },
    inn: {
        show: true,
        title: i18n.t("message.inn"),
        sortable: true,
        column: "inn",
    },
    medical_organization_type: {
        show: true,
        title: i18n.t("message.medicalOrganizationTypes"),
        sortable: true,
        column: "medical_organization_type",
    },
    profilLink: {
        show: true,
        title: i18n.t("message.profilLink"),
        sortable: true,
        column: "profilLink",
    },
    region: {
        show: true,
        title: i18n.t("message.region"),
        sortable: true,
        column: "region",
    },
    city_district: {
        show: true,
        title: i18n.t("message.cityDistrict"),
        sortable: true,
        column: "city_district",
    },
    created_at: {
        show: true,
        title: i18n.t("message.created_at"),
        sortable: true,
        column: "created_at",
    },
    updated_at: {
        show: false,
        title: i18n.t("message.updated_at"),
        sortable: true,
        column: "updated_at",
    },
    settings: {
        show: true,
        title: i18n.t("message.settings"),
        sortable: false,
        column: "settings",
    },
};
