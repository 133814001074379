import Layout from '@/layouts/EmptyLayout'
export default [{
  path: '/register',
  name: 'register',
  meta: {
    layout: 'empty',
    public: true
  },
  component: () => import('@/views/register/index')
}]
