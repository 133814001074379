import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import store from "@/store/index";
import { model } from "./properties/model";
import { i18n } from "@/utils/i18n";
import _ from "lodash";

export const mutations = {
    SET_LIST: (state, clinics) => {
        state.list = clinics;
    },
    SET_INVENTORY: (state, clinics) => {
        state.inventory = _.orderBy(clinics, 'brand_name', ['asc', 'desc']); 
    },
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value;
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order;
    },
    SET_MODEL: (state, clinics) => {
        state.model.id = clinics.id;
        state.model.user_id = clinics.user_id;
        state.model.clinic_id = clinics.clinic_id;
        state.model.is_parent = clinics.is_parent;
        state.model.legal_entity_name = clinics.legal_entity_name;
        state.model.brand_name = clinics.brand_name;
        state.model.clinic_type = clinics.clinic_type;
        state.model.type_id = clinics.type_id;
        state.model.medical_organization_type_name = clinics.medical_organization_type_name;
        state.model.inn = clinics.inn;
        state.model.phone_number = clinics.phone_number;
        state.model.email = clinics.email;
        state.model.region_id = clinics.region_id;
        state.model.region = clinics.region;
        state.model.city_district_id = clinics.city_district_id;
        state.model.city_district = clinics.city_district;
        state.model.address = clinics.address;
        state.model.work_region_id = clinics.work_region_id;
        state.model.work_region = clinics.work_region;
        state.model.work_city_id = clinics.work_city_id;
        state.model.work_city_district = clinics.work_city_district;
        state.model.work_address = clinics.work_address;
        state.model.time_of_registered = clinics.time_of_registered;
        state.model.images = clinics.images;
        state.model.created_at = clinics.created_at;
        state.model.updated_at = clinics.updated_at;

        if (clinics.deal) {
            state.model.deal_number = clinics.deal.deal_number,
            state.model.deal_clinic_id = clinics.deal.deal_clinic_id,
            state.model.deal_inn = clinics.deal.deal_clinic_inn,
            state.model.deal_validity_period = clinics.deal.validity_period 
        }

        /** Set Old Clinic Items */
        state.items = [];
        if (clinics.items && _.isArray(clinics.items)) {
            clinics.items.forEach((elem) => {
                state.items.push({
                    id: elem.id,
                    clinic_id: elem.clinic_id,
                    bank_name: elem.bank_name,
                    r_s_bank: elem.r_s_bank,
                    mfo: elem.mfo,
                    currency: elem.currency,
                    currency_id: elem.currency_id,
                });
            });
        }   
         
        state.building_informations_of_clinics = [];
        if (
            clinics.building_informations_of_clinics &&
            _.isArray(clinics.building_informations_of_clinics)
        ) {
            clinics.building_informations_of_clinics.forEach((building) => {
                state.building_informations_of_clinics.push({
                    id: building.id,
                    clinic_id: building.clinic_id,
                    cadastre_number: building.cadastre_number,
                    property_ownership_id: building.property_ownership_id,
                    property_ownership: building.property_ownership,
                    document_name: building.document_name,
                    document_number: building.document_number,
                    document_date: building.document_date,
                    file: building.file,
                    fayls: []
                });
            });
        }
    },
    SET_FILTERED_FILIAL: (state, filial) => {
        state.filtered_filial = filial;
    },
    SET_DOCUMENTS: (state, documents) => {state.documents = documents;},
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },
    EMPTY_FILIAL: (state) => {
        state.filtered_filial = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
};
