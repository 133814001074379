import { getToken } from "@/utils/auth";

export const state = {
    token: getToken(),
    name: "",
    phone_number: "",
    role: null,
    expires_in: 600000,
    slug: "",
    role: "",
    status: null,
    user: {}
};
