import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

var routes = [{
    path: "/",
    name: "Home",
    meta: {
      layout: "main"
    },
    component: Home
  },
];

/**
 * Load all routes from modulea folder
 */
const request = require.context("./modules", true, /.js/);

request.keys().map(module => {
  routes = routes.concat(request(module).default);
});

const router = new VueRouter({
  routes: routes,
  mode: "history"
});

router.mode = 'html5'

export default router;

