<template>
    <div>
        <el-button
            type="success"
            size="medium"
            :loading="loading"
            :disabled="loading ? true : false"
            @click="submit(false)"
            plain
        >
        Saqlash
        </el-button>
        <el-button type="warning" size="medium" @click="close()" plain>
            Yopish
        </el-button>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: {
        loading: {
            type: Boolean,
        },
        button_type: {
            type: String,
            default: "store",
        },
        permission: {
            type: String,
        },
    },
    // computed: {
    //     ...mapGetters({
    //         permissions: "auth/permissions",
    //     }),
    // },
    methods: {
        check: function (column, event) {
            this.$emit("c-change", {
                key: column,
                value: event,
            });
        },
        submit() {
            this.$emit("c-submit");
        },
        close() {
            this.$emit("c-close");
        },
        checkPermission(permission) {
            return this.permissions.some(
                (per) => per.slug == permission + "@" + "update"
            )
                ? true
                : false;
        },
    },
};
</script>
<style scoped>
.el-dropdown-menu__item > label {
    margin-bottom: 0.1rem !important;
}

.el-dropdown-menu {
    max-height: 720px !important;
    overflow-y: scroll;
}

.el-dropdown-menu__item,
.el-checkbox__label,
.el-checkbox {
    font-size: 12px !important;
}
</style>
