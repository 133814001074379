export const getters = {
  list: state => state.list,
  inventory: state => state.inventory,
  educations: state => state.educations,
  categories: state => state.qualification_categories,
  academic_deegres: state => state.academic_deegres,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort 
};
