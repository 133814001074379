<template>
    <el-dropdown szie="mini" @command="handleCommand">
        <span class="el-dropdown-link khan-style-icon">
            <i class="el-icon-setting"></i>
            <i class="el-icon-arrow-down"></i>
        </span>
        <el-dropdown-menu slot="dropdown" size="mini">
            <el-dropdown-item command="edit" icon="el-icon-edit el-icon--left">
                Изменить
            </el-dropdown-item>
            <el-dropdown-item
                command="delete"
                icon="el-icon-delete el-icon--left"
            >
                Удалить
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: {
        model: {
            type: Object,
            required: true,
        },
        actions: {
            type: Array,
        },
        name: {
            type: String,
            required: false,
        },
        permissionShow: {
            type: String,
            required: false,
            default: "AuthenticateController@login",
        },
        permissionDestroy: {
            type: String,
            required: false,
            default: "AuthenticateController@login",
        },
    },
    data() {
        return {};
    },
    // computed: {
    //   ...mapGetters({
    //     permissions: "auth/permissions"
    //   })
    // },
    methods: {
        handleCommand(command) {
            if (command === "delete") {
                this.$confirm(
                    "Вы действительно хотите это сделать?",
                    "Предупреждение",
                    {
                        confirmButtonText: "Да",
                        cancelButtonText: "Отмен",
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.$emit(command, this.model);
                    })
                    .catch(() => {
                        this.$message({
                            type: "warning",
                            message: "Операция отменена",
                        });
                    });
            } else {
                this.$emit(command, this.model);
            }
        },
    },
};
</script>
<style>
.khan-style-icon {
    background: #f5f7fb;
    padding: 1px 6px;
    border-radius: 15px;
    border: 1px solid #e6edef;
    margin: auto;
    color: #858585;
}
.khan-style-icon .el-icon-arrow-down {
    font-size: 11px;
    margin-left: 5px;
}
</style>
