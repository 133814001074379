export const rules = {
    name: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "blur",
        },
    ],
    tel_number: [
        {
            required: true,
            message: "Пожалуйста, введите телефон",
            blur: "blur",
        },
    ],
    password: [
        {
            required: true,
            message:
                "Для защиты необходимо придумать безопасный пароль. Он должен содержать: 8 и более символов; прописные латинские буквы; строчные латинские буквы; цифры; знаки пунктуации.",
            blur: "blur",
        },
    ],
    region_id: [
        {
            required: true,
            message: "Пожалуйста, введите область",
            trigger: "change",
        },
    ],
};
