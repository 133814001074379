<template>
    <div class="home-doctors-info hidght445" v-loading="loadingData">
        <div class="card o-hidden">
            <div class="home-table">
                <div id="chart">
                    <apexchart
                        ref="realtimeChart"
                        type="bar"
                        height="600"
                        :options="chartOptions"
                        :series="series"
                    ></apexchart>
                </div>
            </div>
               <!-- <div class="my-pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div> -->
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
export default {
    props: ['dialog'],
    data() {
        return {
            loadingData: false,
            series: [{
            name: "Soni",
            data: [],
        }],
        chartOptions: {
            chart: {
                type: "bar",
                height: 425,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#445686"],
            xaxis: {
                categories: [],
            },
        },
        };
    },
    methods: {
        ...mapActions({
            getAllTypes: "dashboard/getAllTypes",
        }),
        fetchData(){
            if (!this.loadingData) {                
                this.loadingData = true;
                this.getAllTypes()
                .then((res) => {
                    this.loadingData = false; 
                    if (res.types) {
                        this.series[0].data = [];
                        this.chartOptions.xaxis.categories = [];
                        let items_of_series = [], items_of_categories = [];

                        for (let key in res.types){
                            if (res.types.hasOwnProperty(key)) {
                                let chart_data = res.types[key];
                                items_of_series.push({x: chart_data.name, y: chart_data.doctors_count});
                                items_of_categories.push(chart_data.name);
                            }
                        }
                         
                        this.series = [
                            {data: items_of_series}
                        ];

                        this.chartOptions.xaxis = {
                            categories: items_of_categories
                        }
                    }
                })
                .catch((err) => {this.loadingData = false;});
            }
        },
        opened() {
            this.fetchData();
        },
        closed(){
            this.series[0].data = [];
            this.chartOptions.xaxis.categories = [];
        }
    },
};
</script>

