export default [
    {
        path: "/officeClinic",
        name: "officeClinic",
        // redirect: '/officeClinic/infoClinic',

        children: [
            {
                path: "/officeClinic/infoClinic/:id",
                name: "infoClinic",
                meta: {
                    layout: "main",
                    public: true,
                },
                component: () => import("@/views/infoClinic/index"),
            },
            {
                path: "/officeClinic/clinicsFilials/:id",
                name: "clinicsFilials",
                meta: {
                    layout: "main",
                    public: true,
                },
                component: () => import("@/views/clinicsFilials/index"),
            },
            {
                path: "/officeClinic/staffClinic/:id",
                name: "staffClinic",
                meta: {
                    layout: "main",
                    public: true,
                },
                component: () => import("@/views/staffClinic/index"),
            },
            {
                path: "/officeClinic/medicalTechniquesClinic/:id",
                name: "medicalTechniquesClinic",
                meta: {
                    layout: "main",
                    public: true,
                },
                component: () => import("@/views/medicalTechniquesClinic/index"),
            },
            {
                path: "/officeClinic/medicalEquipmentsClinic/:id",
                name: "medicalEquipmentsClinic",
                meta: {
                    layout: "main",
                    public: true,
                },
                component: () => import("@/views/medicalEquipmentsClinic/index"),
            },
            {
                path: "/officeClinic/diskClinic/:id",
                name: "diskClinic",
                meta: {
                    layout: "main",
                    public: true,
                },
                component: () => import("@/views/diskClinic/index"),
            },
            {
                path: "/officeClinic/dacument/:id",
                name: "dacument",
                meta: {
                    layout: "main",
                    public: true,
                },
                component: () => import("@/views/diskClinic/dacument"),
            },
            {
                path: "/officeClinic/licenseTableClinic/:id",
                name: "licenseTableClinic",
                meta: {
                    layout: "main",
                    public: true,
                },
                component: () => import("@/views/licenseTableClinic/index"),
            },
        ],
        meta: {
            layout: "main",
            public: true,
        },
        component: () => import("@/views/officeClinic/index"),
    },
];
