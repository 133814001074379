export const filter = {
  id : null,  
  clinic_id: null,
  filial_id: null,
  region_id: null,
  document_type: '',
  file_name: '', 
  created_at: '',
  updated_at: '' ,
  search: '' 
};
