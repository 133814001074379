import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/doctors",
        method: "get",
        params: params,
    });
}

export function inventory(params) {
    return request({
        url: "/doctors/inventory",
        method: "get",
        params,
    });
}

export function remoteMethod(params) {
    return request({
        url: "/doctors/remoteMethod",
        method: "get",
        params,
    });
}

export function getDoctorsInTheClinic(params) {
    return request({
        url: "/doctors/getDoctorsInTheClinic",
        method: "get",
        params,
    });
}

export function show(id) {
    return request({
        url: `/doctors/${id}`,
        method: "get",
    });
}

export function store(data) {
    return request({
        url: "/doctors",
        method: "post",
        data,
    });
}

export function update(data) {
    let data_id = null;
    if (data instanceof FormData) {
        data_id = data.get('id');
    }else{
        data_id = data.id;
    }

    return request({
        url: `/doctors/${data_id}?_method=PUT`,
        method: "post",
        data,
    });
}

export function destroy(id) {
    return request({
        url: `/doctors/${id}`,
        method: "delete",
    });
}

export function filteredDoctorsByClinic(params) {
    return request({
        url: `/doctors/filteredDoctorsByClinic`,
        method: "get",
        params
    });
}

export function downloadFile(params) {
    return request({
        url: '/doctors/downloadFile',
        method: 'get',
        responseType: 'blob',
        params
    })
}
