import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/licenseRequirementEquipments",
        method: "get",
        params: params,
    });
}

export function inventory(params) {
    return request({
        url: "/licenseRequirementEquipments/inventory",
        method: "get",
        params,
    });
}

export function show(id) {
    return request({
        url: `/licenseRequirementEquipments/${id}`,
        method: "get",
    });
}

export function editItems(id) {
	return request({
		 url: `/licenseRequirementEquipments/editItems/${id}`,
		 method: "get",
	});
}

export function store(data) {
    return request({
        url: "/licenseRequirementEquipments",
        method: "post",
        data,
    });
}

export function update(data) {
    return request({
        url: `/licenseRequirementEquipments/${data.id}`,
        method: "put",
        data,
    });
}

export function destroy(id) {
    return request({
        url: `/licenseRequirementEquipments/${id}`,
        method: "delete",
    });
}
