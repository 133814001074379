export const model = {
    id: null,
    clinic_id: null,
    filial_ids: [],
    procedure_ids: [],
    check_license_id: null,
    application_type: true,
    review_time_id: null,
    nurse_type: true,
    created_at: "",
    updated_at: "",
};
