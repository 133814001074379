export const model = {
  id: null,
  clinic_id: null,
  filial_id: null,
  name: '',
  series_and_number: '',
  passport: '',
  date_of_manufacture: '',
  manual: '',
  document: '',
  document_number: '',
  document_date: '',
  certificate: '',
  certificate_number: '',
  certificate_date: '',
  property_ownership_id: null,
  condition_id: null,
  how_long_use_it: null,
  created_at: '',
  updated_at: '',
};
