<template>
    <div>
        <div class="home-doctors-info">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="card o-hidden">
                        <div class="card-header home-d">
                            <h5 class="flex-1">Litsenziyalar dinamikasi</h5>
                            <el-radio-group v-model="radio" class="full-btn-h">
                                <el-radio-button value="yearly" label="yearly">1 Y</el-radio-button>
                                <el-radio-button value="six_monthly" label="six_monthly">6 OY</el-radio-button>
                                <el-radio-button value="monthly" label="monthly">1 OY</el-radio-button>
                                <el-radio-button value="weekly" label="weekly">1 H</el-radio-button>
                                <el-radio-button value="daily" label="daily">1 K</el-radio-button>
                            </el-radio-group>
                            <el-button
                                icon="el-icon-setting"
                                class="asosy-btn-d mt10"
                                @click="filterHome = true"
                            >
                            </el-button>
                        </div>
                        <div class="rezultat">
                            <div class="itme-re">
                                <div>{{ count_of_succeed }} <i class="el-icon-success"></i></div>
                                <span>Tasdiqlandi</span>
                            </div>
                            <!-- <div class="itme-re">
                                <div>350 <i class="el-icon-remove"></i></div>
                                <span>Ko'rilmoqda</span>
                            </div> -->
                            <div class="itme-re">
                                <div>{{ count_of_rejected }} <i class="el-icon-error"></i></div>
                                <span>Bekor qilindi</span>
                            </div>
                        </div>
                        <div class="home-table">
                            <div id="chart">
                                <apexchart
                                    type="bar"
                                    height="350"
                                    :options="chartOptions"
                                    :series="series"
                                ></apexchart>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- end home-doctors-info -->

        <div class="my-modal">
            <el-drawer
                title="I am the title"
                :visible.sync="filterHome"
                :with-header="false"
                :append-to-body="true"
                size="40%"
                custom-class="my-modal-khan"
            >
                <FilterHomeDoctor></FilterHomeDoctor>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import FilterHomeDoctor from "./components/filter";
export default {
    components: {
        FilterHomeDoctor,
    },
    data() {
        return {
            radio: "weekly",
            count_of_succeed: 0,
            count_of_rejected: 0,
            filterHome: false,
            series: [
                {
                    name: "Tasdiqlandi",
                    data: [],
                },
                {
                    name: "Bekor qilindi",
                    data: [],
                },
            ],

            chartOptions: {
                chart: {
                    type: "bar",
                    height: 350,
                     toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },

                xaxis: {
                    categories: [],
                },
                // yaxis: {
                //     title: {
                //         text: "Text test",
                //     },
                // },
                fill: {
                    opacity: 1,
                    colors: ["#0081af", "#ef476f"],
                },
                legend: {
                    markers: {
                        colors: ["#0081af", "#ef476f"],
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: "#fff",
                        fillColors: ["#0081af", "#ef476f"],
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0,
                    },
                },

                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },
        };
    },
    watch: {
        radio: {
            handler: function(){
                let debounce_func = _.debounce(this.fetchData, 500);
                debounce_func();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions({
            editChart: 'dashboard/diynamic_of_licenses'
        }),
        fetchData(){
            this.editChart({type: this.radio}).then((res) => {
                this.count_of_succeed = res.data.count_of_succeed;
                this.count_of_rejected = res.data.count_of_rejected;
                if (res.data.chart_data){
                    this.chartOptions.xaxis.categories = [];
                    this.series[0].data = [];
                    this.series[1].data = [];
                    let labels = [], 
                    data1 = [], 
                    data2 = [];
                    for (let key in res.data.chart_data) {
                        if (res.data.chart_data.hasOwnProperty(key)) {
                            let chart_data = res.data.chart_data[key];
                            labels.push(chart_data.label);
                            data1.push({x: chart_data.label, y: chart_data.count_succeed});
                            data2.push({x: chart_data.label, y: chart_data.count_rejected});
                        }
                    }

                    this.chartOptions.xaxis = {
                        categories: labels
                    };
                    this.series = [
                        {
                            name: "Tasdiqlandi",
                            data: data1
                        },
                        {
                            name: "Bekor qilindi",
                            data: data2
                        }
                    ];
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }
};
</script>
