import Layout from '@/layouts/EmptyLayout'
export default [{
  path: '/medicalTechniques',
  name: 'medicalTechniques',
  meta: {
    layout: 'main',
    public: true
  },
  component: () => import('@/views/medicalTechniques/index')
}]
