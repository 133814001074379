import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/clinics",
        method: "get",
        params: params,
    });
}

export function inventory(params) {
    return request({
        url: "/clinics/inventory",
        method: "get",
        params,
    });
}

export function show(id) {
    return request({
        url: `/clinics/${id}`,
        method: "get",
    });
}

export function store(data) {
    return request({
        url: "/clinics",
        method: "post",
        data,
    });
}

export function update(data) {
    let data_id = null;
    if (data instanceof FormData) {
        data_id = data.get('id');
    }else{
        data_id = data.id;
    }

    return request({
        url: `/clinics/${data_id}?_method=PUT`,
        method: "post",
        data,
    });
}

export function filteredFilial(params) {
    return request({
        url: `/clinics/filteredFilial`,
        method: "get",
        params
    });
}

export function getDocumentsOfClinic(params) {
    return request({
        url: `/clinics/getDocumentsOfClinic`,
        method: "get",
        params
    });
}

export function destroy(id) {
    return request({
        url: `/clinics/${id}`,
        method: "delete",
    });
}

export function getAllDocumentsOfClinic(id) {
    return request({
        url: `/clinics/getAllDocumentsOfClinic/${id}`,
        method: "get",
    });
}

export function checkINNByAnotherInns(data) {
    return request({
        url: "/clinics/checkINNByAnotherInns",
        method: "post",
        data,
    });
}