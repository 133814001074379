import {
    index,
    inventory,
    show,
    store,
    update,
    destroy,
    filteredFilial,
    getDocumentsOfClinic,
    getAllDocumentsOfClinic,
    checkINNByAnotherInns
} from "@/api/clinic/clinics";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then((res) => {
                    commit("SET_LIST", res.data.result.data.clinics);
                    commit("UPDATE_PAGINATION", {
                        key: "total",
                        value: res.data.result.data.pagination.total,
                    });
                    resolve(res.data.result.data.clinics);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params)
                .then((res) => {
                    commit("SET_INVENTORY", res.data.result.data.clinics);
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then((res) => {
                    commit("SET_MODEL", res.data.result.data.clinic);
                    resolve(res);
                })
                .catch((err) => {
                    console.log(err)
                    reject(err.response.data);
                });
        });
    },
    
    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    filteredFilial({commit}, params = {}){
        return new Promise((resolve, reject) => {
            filteredFilial(params).then((res) => {
                commit('SET_FILTERED_FILIAL', res.data.result.data.filials);
                resolve(res.data.result.data);
            }).catch((err) => {
               reject(err) 
            });
        });
    },

    getDocumentsOfClinic({commit}, params = {}){
        return new Promise((resolve, reject) => {
            getDocumentsOfClinic(params).then((res) => {
                commit('SET_DOCUMENTS', res.data.result.data.documents);     
                resolve(res.data.result.data); 
            }).catch((err) => {
               reject(err) 
            });
        });
    },

    getAllDocumentsOfClinic({ commit }, id) {
        return new Promise((resolve, reject) => {
            getAllDocumentsOfClinic(id)
                .then((res) => {
                    resolve(res.data.result.data);
                })
                .catch((err) => {
                    console.log(err)
                    reject(err.response.data);
                });
        });
    },

    checkINNByAnotherInns({ commit }, data) {
        return new Promise((resolve, reject) => {
            checkINNByAnotherInns(data)
                .then((res) => {
                    resolve(res.data.result.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve();
        });
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort);
    },

    updateFilter({ commit }, filter) {
        commit("SET_FILTER", JSON.parse(JSON.stringify(filter)));
    },

    updateColumn({ commit }, obj) {
        commit("UPDATE_COLUMN", obj);
    },
    updatePagination({ commit }, pagination) {
        commit("UPDATE_PAGINATION", pagination);
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve();
        });
    },
};
