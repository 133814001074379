<template>
    <div>
        <el-select
            :value="selected"
            @input="dispatch"
            :placeholder="placeholder || $t('message.academic_degree')"
            filterable
            clearable
            :size="size"
            class="w-100"
            :disabled="disabled"
        >
            <el-option
                v-for="(academic_degree, index) in academic_degrees"
                :key="'academic_degree-' + index"
                :label="academic_degree.name"
                :value="academic_degree.id"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
    props: {
        size: {
            default: "small",
        },
        placeholder: {
            default: null,
        },
        id: {
            default: null,
        },
        disabled: {
            default: false
        }
    },
    watch: {
        id: {
            handler: function () {
                setTimeout(() => {
                    this.selected = this.id;
                });
            },
            immediate: true,
        },
    },
    data() {
        return {
            selected: null,
        };
    },
    mounted() {
        if (this.academic_degrees && this.academic_degrees.length === 0)
            this.updateInventory();
    },
    computed: {
        ...mapGetters({
            academic_degrees: "academicDegrees/inventory",
        }),
    },
    methods: {
        ...mapActions({
            updateInventory: "academicDegrees/inventory",
        }),
        dispatch(e) {
            this.$emit("input", e);
            this.selected = e;
        },
    },
};
</script>
