import { i18n } from "@/utils/i18n";

export const columns = {
    id: {
        show: true,
        title: i18n.t("message.n"),
        sortable: true,
        column: "id",
    },
    name: {
        show: true,
        title: i18n.t("message.name3"),
        sortable: true,
        column: "name",
    },
    direction: {
        show: true,
        title: i18n.t("message.direction"),
        sortable: true,
        column: "direction",
    },
    typesOfEmployee: {
        show: true,
        title: i18n.t("message.typesOfEmployees"),
        sortable: true,
        column: "typesOfEmployee",
    },
    clinic: {
        show: true,
        title: i18n.t("message.clinics"),
        sortable: true,
        column: "clinic",
    },
    filial: {
        show: false,
        title: i18n.t("message.filials"),
        sortable: true,
        column: "filial",
    },
    
    passport_series_and_number: {
        show: true,
        title: i18n.t("message.passport_series_and_number"),
        sortable: true,
        column: "passport_series_and_number",
    },
    mobile_tel_number: {
        show: true,
        title: i18n.t("message.tel_number"),
        sortable: true,
        column: "mobile_tel_number",
    },
    region: {
        show: true,
        title: i18n.t("message.region"),
        sortable: true,
        column: "region",
    },
    city_district: {
        show: true,
        title: i18n.t("message.cityDistrict"),
        sortable: true,
        column: "city_district",
    },
    profilLink: {
        show: true,
        title: i18n.t("message.profilLink"),
        sortable: true,
        column: "profilLink",
    },
    created_at: {
        show: true,
        title: i18n.t("message.created_at"),
        sortable: true,
        column: "created_at",
    },
    updated_at: {
        show: false,
        title: i18n.t("message.updated_at"),
        sortable: true,
        column: "updated_at",
    },
    settings: {
        show: true,
        title: i18n.t("message.settings"),
        sortable: false,
        column: "settings",
    },
};
