import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/licenseProceduresConnection",
        name: "licenseProceduresConnection",
        meta: {
            layout: "main",
            public: true,
        },
        component: () =>
            import("@/views/settingsBlocks/licenseProceduresConnection/index"),
    },
];
