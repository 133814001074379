<template>
    <el-dropdown
        @command="handleCommand"
        :hide-on-click="false"
        class="table-bottons-right"
    >
        <span class="el-dropdown-link more_icons">
            <i class="el-icon-more-outline"></i>
        </span>
        <el-dropdown-menu
            slot="dropdown"
            class="dropdown-menumy-style"
        >
            <el-dropdown-item command="received">
                <el-button type="text"> Qabul qilish <i class="el-icon-right"></i></el-button> 
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
export default {
    props: {
        model: {
            type: Object,
            required: true,
        },
        actions: {
            type: Array,
        },
    },
    methods: {
        handleCommand(command){
        if (command == 'received') {
            this.$confirm('Arizani qabul qilish', {
                confirmButtonText: 'Ha',
                cancelButtonText: 'Yopish',
                type: 'warning'
                }).then(() => {
                    this.$emit(command, this.model);
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Arizani qabul qilish rad etildi'
                });          
            });
        }
    }
    }
}
</script>