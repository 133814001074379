export const rules = {
    condition_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
    conclusion_text: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "change",
        },
    ],
};
