import Layout from '@/layouts/EmptyLayout'
export default [{
  path: '/licenseTable',
  name: 'licenseTable',
  meta: {
    layout: 'main',
    public: true
  },
  component: () => import('@/views/licenseTable/index')
}]
