export const model = {
    id: null,
    user_id: null,
    clinic: null,
    clinic_id: null,
    filial: null,
    filial_id: null,
    name: "",
    surname: "",
    lastname: "",
    id_number: null,
    passport_series_and_number: "",
    brith_date: "",
    stir: "",
    inn: "",
    email: "",
    work_tel_number: "",
    mobile_tel_number: "",
    gender: "",
    nation_id: null,
    place_of_brith: "",
    region: null,
    region_id: null,
    city_district: null,
    city_district_id: null,
    neighborhood: "",
    home: "",
    apartment_number: "",
    image: null,
    typesOfEmployee: null,
    types_of_employee_id: null,
    direction: null,
    direction_id: null,
    created_at: "",
    updated_at: "", 
};
