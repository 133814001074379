import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/qualificationCategories",
        name: "qualificationCategories",
        meta: {
            layout: "main",
            public: true,
        },
        component: () =>
            import("@/views/settingsBlocks/qualificationCategories/index"),
    },
];
