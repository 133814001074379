import Layout from '@/layouts/EmptyLayout'
export default [{
  path: '/confirm',
  name: 'confirm',
  redirect: "/confirm/allApplications",
  meta: {
    layout: 'main',
    public: true
  },
  children: [
    {
        path: "/confirm/allApplications",
        name: "confirmAllApplications",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/confirm/allApplications/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/confirm/newApplications",
        name: "confirmNewApplications",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/confirm/newApplications/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/confirm/confirmUnderReview",
        name: "confirmUnderReview",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/confirm/underReview/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
        path: "/confirm/rejected",
        name: "confirmRejected",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/confirm/rejected/index"),
        // meta: { title: i18n.t('message.user') }
    },
    {
      path: "/confirm/timeRunningOut",
      name: "confirmTimeRunningOut",
      meta: {
          layout: "main",
      },
      component: () => import("@/views/confirm/timeRunningOut/index"),
      // meta: { title: i18n.t('message.user') }
    },
    {
      path: "/confirm/success",
      name: "confirmSuccess",
      meta: {
          layout: "main",
      },
      component: () => import("@/views/confirm/success/index"),
      // meta: { title: i18n.t('message.user') }
    },    
  ],
  component: () => import('@/views/confirm/index')
}]
