<template>
    <div>
        <div class="home-doctors-info">
            <div class="card o-hidden">
                <div class="card-header home-d">
                    <h5 class="flex-1">Tibbiy xizmat turlari diagrammasi</h5>
                </div>

                <div class="home-table">
                    <div id="chart">
                        <apexchart
                            type="pie"
                            width="400"
                            :options="chartOptions"
                            :series="series"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <!-- end home-doctors-info -->
    </div>
</template>
<script>
export default {
    props: ['own_data'],
    components: {},
    watch: {
        $props: {
            handler() {
                for (let key in this.own_data) {
                    if (this.own_data[key] && !(key == 0)) {
                        this.series.push(this.own_data[key]['quantity']);
                        this.chartOptions.labels.push(this.own_data[key]['name']);
                    }
                }
            },
            deep: true,
            // immediate: true
        }
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    width: 480,
                    type: "pie",
                },
                labels: [],
                legend: {
                    show: true,
                },
                theme: {
                    monochrome: {
                        enabled: true,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            series: [],
            chartOptions: {
                chart: {
                    width: 480,
                    type: "pie",
                },
                labels: [],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
        };
    },
};
</script>
