export const rules = {
    name: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "change",
        },
    ],
    surname: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "change",
        },
    ],
    clinic_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
    types_of_employee_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
    work_tel_number: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "change",
        },
    ],
    mobile_tel_number: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            blur: "change",
        },
    ],
    region_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
    city_district_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
    direction_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
};
