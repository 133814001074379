import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/servicesOfClinic",
        method: "get",
        params: params,
    });
}

export function inventory(params) {
    return request({
        url: "/servicesOfClinic/inventory",
        method: "get",
        params,
    });
}

export function show(id) {
    return request({
        url: `/servicesOfClinic/${id}`,
        method: "get",
    });
}

export function store(data) {
    return request({
        url: "/servicesOfClinic",
        method: "post",
        data,
    });
}

export function update(data) {
    return request({
        url: `/servicesOfClinic/${data.id}`,
        method: "put",
        data,
    });
}

export function destroy(id) {
    return request({
        url: `/servicesOfClinic/${id}`,
        method: "delete",
    });
}

export function create() {
    return request({
        url: 'servicesOfClinic/create',
        method: 'get',
    })
}
