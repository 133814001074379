export const rules = {
    type_of_employee_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
    direction_id: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
};
