<template>
    <div>
        <div class="home-doctors-info hidght445">
            <div class="card o-hidden">
                <div class="card-header home-d">
                    <h5>Tizimdagi shifokorlar</h5>
                </div>
                <div class="home-table">
                    <table>
                        <thead>
                            <tr>
                                <th class="h1-calom">(F.I.SH)</th>
                                <th>Tibbiy muassasa</th>
                                <th>Yo’nalish</th>
                                <th>Daraja</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td>
                                    <div class="t-h-user-doctor">
                                        <div v-if="item.image['id']">
                                             <img
                                                :src="appURL + item.image.path"
                                            />
                                        </div>
                                        <div v-else>
                                            <img
                                                src="./../../../public/img/avatar_doctor.png"
                                            />
                                        </div>
                                        <div>
                                            {{ item.name ? item.name : '' }}  {{ item.surname ? item.surname : ''}}  {{ item.lastname ? item.lastname : '' }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span v-if="item.filial">{{ item.filial.legal_entity_name }}</span>
                                    <span v-else>{{ item.clinic ? item.clinic.legal_entity_name : '' }}</span>
                                </td>
                                <td>{{ item.direction ? item.direction.name : '' }} </td>
                                <td>{{ item.typeOfEmployee ? item.typeOfEmployee.name : '' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- end home-doctors-info -->
    </div>
</template>
<script>
import _ from 'lodash';
export default {
    props: ['doctors'],
    watch: {
        $props: {
            handler() {
                let image = {};
                for (let i = 0; i < this.doctors.length; i++) {
                   if (i < 6) {
                        if (!_.isEmpty(this.doctors[i].images)){
                            image['id'] = this.doctors[i].images[0].id;
                            image['path'] = this.doctors[i].images[0].path;
                        }else{
                            image['id'] = null;
                            image['path'] = null;
                        }
                        this.items.push({
                            id: this.doctors[i].id,
                            name: this.doctors[i].name,
                            surname: this.doctors[i].surname,
                            lastname: this.doctors[i].lastname,
                            clinic: this.doctors[i].clinic,
                            filial: this.doctors[i].filial,
                            direction: this.doctors[i].direction,
                            typeOfEmployee: this.doctors[i].typeOfEmployee,
                            image: image
                        });
                        image = {}; 
                   }
                   else{
                    break;
                   }
                }  
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            appURL: process.env.VUE_APP_URL_DOCS,
            items: []
        };
    },
};
</script>
