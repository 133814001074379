export const rules = {
    name: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
    fullname: [
        {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
        },
    ],
};
