export const getters = {
  list: state => state.list,
  confirmation: state => state.counts_for_confirmation,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort 
};
