import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/submitApplication",
        name: "submitApplication",
        meta: {
            layout: "main",
            public: true
        },
        component: () => import("@/views/submitApplication/index")
    }
];
