import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/licenseProcedures",
        name: "licenseProcedures",
        meta: {
            layout: "main",
            public: true
        },
        component: () =>
            import("@/views/settingsBlocks/licenseProcedures/index")
    }
];
