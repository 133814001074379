export const model = {
    id: null,
    user_id: null,
    clinic_id: null,
    legal_entity_name: "",
    brand_name: "",
    type_id: null,
    inn: "",
    phone_number: "",
    email: "",
    region_id: null,
    city_district_id: null,
    work_region_id: null,
    work_city_id: null,
    clinic_type: null,
    address: "",
    work_address: "",
    time_of_registered: "",
    created_at: "",
    updated_at: "",
    deal_number: null,
    deal_clinic_id: null,
    deal_inn: null,
    deal_validity_period: '' 
};
