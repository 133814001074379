import request from '@/utils/request'

export function index(params) {
    return request({
        url: "/documents",
        method: "get",
        params: params,
    });
}

export function getDocuments(data) {
    return request({
        url: "/documents/getDocuments",
        method: "post",
        data,
    });
}

export function downloadFile(data) {
    return request({
        url: '/documents/downloadFile',
        method: 'post',
        responseType: 'blob',
        data
    })
}