<template>
    <div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
                <RightInfo :statistics="statistics"></RightInfo>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
                <LeftInfo :equipments="equipments"></LeftInfo>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import LeftInfo from "./left.vue";
import RightInfo from "./right.vue";
export default {
    name: "Home",
    components: {
        LeftInfo,
        RightInfo,
    },
   created(){
        let debounce_func = _.debounce(this.load, 800);
        debounce_func();
    },
    data(){
        return {
            loadingData: false,
            equipments: [],
            statistics: []
        }
    },
    methods:{
        ...mapActions({
            getLastEquipments: 'dashboard/getLastEquipments'
        }),
        load(){
            if (!this.loadingData){
                this.getLastEquipments().then((res) => {
                    this.equipments = res.data.equipments;
                    this.statistics = res.data.statistic_of_equipments;    
                }).catch((err) => {
                    console.log(err)
                });
            }
        }
    }
};
</script>
