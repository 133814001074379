import { i18n } from "@/utils/i18n";

export const columns = {
    id: {
        show: true,
        title: i18n.t("message.n"),
        sortable: true,
        column: "id",
    },
    clinic: {
        show: true,
        title: i18n.t("message.clinics"),
        sortable: true,
        column: "clinic",
    },
    filial: {
        show: true,
        title: i18n.t("message.filials"),
        sortable: true,
        column: "filial",
    },
    region: {
        show: true,
        title: i18n.t("message.region"),
        sortable: true,
        column: "region",
    },
    document_type: {
        show: true,
        title: i18n.t("message.document_type"),
        sortable: true,
        column: "document_type",
    },
    file_name: {
        show: true,
        title: i18n.t("message.name"),
        sortable: true,
        column: "file_name",
    },
    created_at: {
        show: true,
        title: i18n.t("message.created_at"),
        sortable: true,
        column: "created_at",
    },
    updated_at: {
        show: false,
        title: i18n.t("message.updated_at"),
        sortable: true,
        column: "updated_at",
    },
    settings: {
        show: true,
        title: i18n.t("message.settings"),
        sortable: false,
        column: "settings",
    },
};
