<template>
    <div>
        <div class="home-doctors-info">
            <div class="card o-hidden">
                <div class="card-header home-d">
                    <h5 class="flex-1">Tibbiy xizmat turlari diagrammasi</h5>
                </div>

                <div class="home-table">
                    <div id="chart">
                        <apexchart
                            type="pie"
                            width="400"
                            :options="chartOptions"
                            :series="series"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <!-- end home-doctors-info -->
    </div>
</template>
<script>
export default {
    props: ['report'],
    components: {},
    watch: {
        $props: {
            handler() {
                for(let key in this.report){
                    if (this.report[key] && !(key == 1)) {
                        this.series.push(this.report[key]['quantity']);
                        this.chartOptions.labels.push(this.report[key]['name']);
                    }
                }
            },
            deep: true,
            // immediate: true
        }
    },
    data() {
        return {
            // radio2: "1 Y",
            // filterHome: false,
            series: [],
            chartOptions: {
                chart: {
                    width: 480,
                    type: "pie",
                },
                labels: [],
                legend: {
                    show: true,
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        // color: '#00e397',
                        // shadeIntensity: 1
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 400,
                            },
                            legend: {
                                position: "bottom",
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return val + "%"
                                },
                            },
                        },
                    },
                ],
            },
           series: [],
            chartOptions: {
                chart: {
                    width: 480,
                    type: "pie",
                },
                labels: [],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 400,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
        };
    },
};
</script>
