<template>
    <div>
        <div class="home-doctors-info">
            <div class="card o-hidden">
                <div class="card-header home-d">
                    <h5 class="flex-1">Tizim tibbiy texnikalar</h5>
                    <router-link :to="{ name: 'medicalTechniques' }">
                        <el-button
                            icon="el-icon-top-right"
                            class="mini-style2 mt10"
                            @click="centerDialogVisible = true"
                        >
                        </el-button>
                    </router-link>
                </div>
                <div class="home-table">
                    <table>
                        <thead>
                            <tr>
                                <th class="h1-calom">Nomi</th>
                                <th>Turi</th>
                                <th>Klinika</th>
                                <th>Tugashi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in techniques" :key="index">
                                <td>
                                    <div class="t-h-user-doctor">
                                        <div class="tex-name">
                                            {{ item.name ? item.name : '' }}
                                        </div>
                                    </div>
                                </td>
                                <td>{{ item.technique_type ? item.technique_type.name : '' }}</td>
                                <td>{{ item.clinic ? item.clinic.brand_name : '' }}</td>
                                <td>01.01.2030</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- end home-doctors-info -->


        
    </div>
</template>
<script>
export default {
    props: ['techniques'],
    data() {
        return {
            filterHome: false,
            centerDialogVisible: false,
        };
    },
};
</script>
