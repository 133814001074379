import { i18n } from "@/utils/i18n";

export const columns = {
    id: {
        show: true,
        title: i18n.t("message.n"),
        sortable: true,
        column: "id",
    },
    license: {
        show: true,
        title: i18n.t("message.license"),
        sortable: true,
        column: "license",
    },
    prosent: {
        show: true,
        title: i18n.t("message.foyiz"),
        sortable: true,
        column: "prosent",
    },
    status: {
        show: true,
        title: i18n.t("message.status"),
        sortable: true,
        column: "status",
    },
    statement_type: {
        show: true,
        title: i18n.t("message.statement_type"),
        sortable: true,
        column: "statement_type",
    },
    organization: {
        show: true,
        title: i18n.t("message.organization"),
        sortable: true,
        column: "organization",
    },
    application_number: {
        show: true,
        title: i18n.t("message.application_number"),
        sortable: true,
        column: "application_number",
    },
    cabinetType: {
        show: true,
        title: i18n.t("message.cabinet_type"),
        sortable: true,
        column: "cabinetType",
    },
    created_at: {
        show: true,
        title: i18n.t("message.application_created_at"),
        sortable: true,
        column: "created_at",
    },
    updated_at: {
        show: false,
        title: i18n.t("message.updated_at"),
        sortable: true,
        column: "updated_at",
    },
    settings: {
        show: true,
        title: i18n.t("message.settings"),
        sortable: false,
        column: "settings",
    },
};
