<template>
    <div>
        <el-select
            :value="selected"
            @input="dispatch"
            :placeholder="placeholder || $t('message.equipment_type')"
            filterable
            clearable
            :size="size"
            class="w-100"
        >
            <el-option
                v-for="(equipment_type, index) in equipment_types"
                :key="'equipment_type-' + index"
                :label="equipment_type.name"
                :value="equipment_type.id"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
    props: {
        size: {
            default: "small",
        },
        placeholder: {
            default: null,
        },
        id: {
            default: null,
        },
    },
    watch: {
        id: {
            handler: function () {
                setTimeout(() => {
                    this.selected = this.id;
                });
            },
            immediate: true,
        },
    },
    data() {
        return {
            selected: null,
        };
    },
    mounted() {
        if (this.equipment_types && this.equipment_types.length === 0)
            this.updateInventory();
    },
    computed: {
        ...mapGetters({
            equipment_types: "equipmentTypes/inventory",
        }),
    },
    methods: {
        ...mapActions({
            updateInventory: "equipmentTypes/inventory",
        }),
        dispatch(e) {
            this.$emit("input", e);
            this.selected = e;
        },
    },
};
</script>
