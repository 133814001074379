import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/propertyOwnership",
        name: "propertyOwnership",
        meta: {
            layout: "main",
            public: true
        },
        component: () =>
            import("@/views/settingsBlocks/propertyOwnership/index")
    }
];
