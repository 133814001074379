import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import store from "@/store/index";
import { model } from "./properties/model";
import { i18n } from "@/utils/i18n";

export const mutations = {
    SET_LIST: (state, regions) => {
        state.list = regions;
    },
    SET_INVENTORY: (state, regions) => (state.inventory = regions),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value;
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order;
    },
    SET_MODEL: (state, filial) => {
        state.model.id = filial.id;
        state.model.clinic = filial.clinic;
        state.model.legal_entity_name = filial.legal_entity_name;
        state.model.brand_name = filial.brand_name;
        state.model.type_id = filial.type_id;
        state.model.inn = filial.inn;
        state.model.email = filial.email;
        state.model.phone_number = filial.phone_number;
        state.model.region_id = filial.region_id;
        state.model.city_district_id = filial.city_district_id;
        state.model.time_of_registered = filial.time_of_registered;
        state.model.address = filial.address;
        state.model.created_at = filial.created_at;
        state.model.updated_at = filial.updated_at;

        state.items = [];
        if (filial.items && _.isArray(filial.items)) {
            filial.items.forEach((elem) => {
                state.items.push({
                    id: elem.id,
                    filial_id: elem.filial_id,
                    r_s_bank: elem.r_s_bank,
                    mfo: elem.mfo,
                    currency_id: elem.currency_id,
                });
            });
        }

        state.building_informations_of_filial = [];
        if (
            filial.building_informations_of_filial &&
            _.isArray(filial.building_informations_of_filial)
        ) {
            filial.building_informations_of_filial.forEach((building) => {
                state.building_informations_of_filial.push({
                    id: building.id,
                    filial_id: building.filial_id,
                    cadastre_number: building.cadastre_number,
                    property_ownership_id: building.property_ownership_id,
                    document_name: building.document_name,
                    document_number: building.document_number,
                    document_date: building.document_date,
                    files: building.files,
                    fayls: []
                });
            });
        }
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
};
