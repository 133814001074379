import Layout from '@/layouts/EmptyLayout'
export default [{
  path: '/rol',
  name: 'rol',
  meta: {
    layout: 'main',
    public: true
  },
  component: () => import('@/views/rol/index')
}]
