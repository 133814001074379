import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/filials",
        method: "get",
        params: params,
    });
}

export function inventory(params) {
    return request({
        url: "/filials/inventory",
        method: "get",
        params,
    });
}

export function show(id) {
    return request({
        url: `/filials/${id}`,
        method: "get",
    });
}

export function store(data) {
    return request({
        url: "/filials",
        method: "post",
        data,
    });
}

export function update(data) {
    let data_id = null;
    if (data instanceof FormData) {
        data_id = data.get('id');
    }else{
        data_id = data.id;
    }

    return request({
        url: `/filials/${data_id}?_method=PUT`,
        method: "post",
        data,
    });
}

export function destroy(id) {
    return request({
        url: `/filials/${id}`,
        method: "delete",
    });
}

export function filteredFilialsByClinicId(params) {
    return request({
        url: `/filials/filialsByClinicId`,
        method: "get",
        params
    });
}
