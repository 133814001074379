<template>
    <div>
        <div class="modal-header">
            <h4>Title Modal</h4>
            <div class="modal-title-header">
                <el-button type="primary" plain>Saqlash</el-button>
                <el-button type="success" plain> Yopish </el-button>
            </div>
        </div>

        <div class="modal-content">
            <el-form ref="form" :model="form" class="my-form">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Faoliyat   Turi">
                            <el-select
                                multiple
                                v-model="form.resgion"
                                placeholder="Faoliyat  Turi"
                            >
                                <el-option label="Test1" value="a1">
                                </el-option>
                                <el-option label="Test1" value="a2">
                                </el-option>
                                <el-option label="Test1" value="a3">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="Viloyatlar">
                            <el-select
                                v-model="form.region"
                                placeholder="Pasport"
                            >
                                <el-option
                                    label="Farg'ona"
                                    value="fer"
                                ></el-option>
                                <el-option
                                    label="Toshkent"
                                    value="tosh"
                                ></el-option>
                                <el-option
                                    label="Buhor"
                                    value="bur"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="Shahar">
                            <el-select
                                v-model="form.region"
                                placeholder="Pasport"
                            >
                                <el-option
                                    label="Farg'ona"
                                    value="fer"
                                ></el-option>
                                <el-option
                                    label="Toshkent"
                                    value="tosh"
                                ></el-option>
                                <el-option
                                    label="Buhor"
                                    value="bur"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                type: [],
            },
        };
    },
    computed: {},
};
</script>
