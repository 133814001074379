<template>
    <div>
        <div class="home-doctors-info">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="card o-hidden">
                        <div class="card-header home-d">
                            <h5 class="flex-1">
                                Litsenziyalar soni o’sishi va kamayishining
                                dinamikasi
                            </h5>
                            <b class="region-b"> O'zbekiston </b>
                            <el-radio-group v-model="radio2" class="full-btn-h">
                                <el-radio-button label="1 Y"> </el-radio-button>
                                <el-radio-button label="6 OY">
                                </el-radio-button>
                                <el-radio-button label="1 Oy">
                                </el-radio-button>
                                <el-radio-button label="1 H"> </el-radio-button>
                                <el-radio-button label="1 K"> </el-radio-button>
                            </el-radio-group>
                            <el-button
                                icon="el-icon-setting"
                                class="asosy-btn-d mt10"
                                @click="filterHome = true"
                            >
                            </el-button>
                        </div>
                        <div class="rezultat">
                            <div class="itme-re">
                                <div>2050 <i class="el-icon-success"></i></div>
                                <span>Faoliyat yuritmoqda</span>
                            </div>

                            <div class="itme-re">
                                <div>150 <i class="el-icon-error"></i></div>
                                <span>Faoliyat bekor qilindi</span>
                            </div>
                        </div>
                        <div class="home-table">
                            <div id="chart">
                                <apexchart
                                    type="area"
                                    height="350"
                                    :options="chartOptions"
                                    :series="series"
                                ></apexchart>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- end home-doctors-info -->

        <div class="my-modal">
            <el-drawer
                title="I am the title"
                :visible.sync="filterHome"
                :with-header="false"
                :append-to-body="true"
                size="40%"
                custom-class="my-modal-khan"
            >
                <FilterHomeDoctor></FilterHomeDoctor>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import FilterHomeDoctor from "./components/filter";
export default {
    components: {
        FilterHomeDoctor,
    },
    data() {
        return {
            radio2: "1 Y",
            filterHome: false,
            series: [
                {
                    name: "Faoliyat yuritmoqda",
                    data: [50, 40, 68, 85, 90, 109, 100, 20, 32, 150, 45, 32],
                },

                {
                    name: "Faoliyat bekor qilindi",
                    data: [15, 25, 55, 20, 30, 70, 60, 11, 32, 45, 32, 34],
                },
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: "area",
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nab",
                        "Dec",
                    ],
                },
                // tooltip: {
                //     x: {
                //         format: "dd/MM/yy HH:mm",
                //     },
                // },
            },
        };
    },
};
</script>
