import Layout from "@/layouts/EmptyLayout";
export default [
    {
        path: "/nation",
        name: "nation",
        meta: {
            layout: "main",
            public: true
        },
        component: () => import("@/views/settingsBlocks/nation/index")
    }
];
