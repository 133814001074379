<template>
    <div class="home-doctors-info mb-5">
        <div class="card o-hidden">
            <h5 class="flex-1 scrol-title">TIZIMDAGI SHIFOKORLAR</h5>
            <div class="slider-scrol">
                <div class="slide">
                    <div class="itme-slider" v-for="(item, index) in doctors" :key="index">
                        <router-link :to="{ name: 'doctorCardInfo', params: {id: item.id} }">
                        <div v-if="item.image['id']">
                            <img :src="appURL + item.image.path" />
                        </div>
                        <div v-else>
                            <img src="./../../../public/img/avatar_doctor.png" />
                        </div>
                            <div class="itme-slider-name">
                                {{ item.name ? item.name : '' }} {{ item.surname ? item.surname : '' }} {{ item.lastname ? item.lastname : '' }}
                            </div>
                            <div class="itme-slider-y">{{ item.direction ? item.direction.name : '' }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
export default {
    name: "homeSilderDoctor",
    props:{
        list: {
            type: Object,
            required: true
        }
    },
    components: {},

    data() {
        return {
            appURL: process.env.VUE_APP_URL_DOCS
        };
    },
    computed: {
        doctors: function(){
            let items = [];
            if (this.list && _.size(this.list.doctors)){
                _.forEach(this.list['doctors'], elem => {
                        let image = {};
                        if (elem.images && _.size(elem.images)) {
                            image['id'] = elem.images[0].id;
                            image['path'] = elem.images[0].path;
                        }else{
                            image['id'] = null;
                            image['path'] = null;
                        }
                        items.push({
                            id: elem.id,
                            name: elem.name,
                            surname: elem.surname,
                            lastname: elem.lastname,
                            direction: elem.direction,
                            image: image
                        });
                        image = {};
                });
                return items;
            }
            return items;
        }
    },
};
</script>