import request from '@/utils/request'

export function chart_statistical_app(params) {
    return request({
        url: "/dashboard/chart_statistical_app",
        method: "get",
        params: params
    });
}

export function diynamic_of_licenses(params) {
    return request({
        url: "/dashboard/diynamic_of_licenses",
        method: "get",
        params: params
    });
}

export function reportForServicesOfClinics() {
    return request({
        url: "/dashboard/reportForServicesOfClinics",
        method: "get",
    });
}

export function getAllTypes() {
    return request({
        url: "/dashboard/getAllTypes",
        method: "get",
    });
}

export function getTypes() {
    return request({
        url: "/dashboard/getTypes",
        method: "get",
    });
}

export function getDoctors() {
    return request({
        url: "/dashboard/getDoctors",
        method: "get",
    });
}
export function getLastClinics() {
    return request({
        url: "/dashboard/getLastClinics",
        method: "get",
    });
}
export function getLastTechniques() {
    return request({
        url: "/dashboard/getLastTechniques",
        method: "get",
    });
}
export function getLastEquipments() {
    return request({
        url: "/dashboard/getLastEquipments",
        method: "get",
    });
}



