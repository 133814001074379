export default [{
  path: '/doctorAdd',
  name: 'doctorAdd',
  meta: {
    layout: 'main',
    public: true
  },
  component: () => import('@/views/doctorAdd/index')
}]

