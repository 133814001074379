    <template>
    <div>
        <div class="home-doctors-info">
            <div class="card o-hidden">
                <div class="card-header home-d">
                    <h5 class="flex-1">
                        Arizalar holati bo’yicha statistik ko’rsatkichlar
                    </h5>
                    <el-radio-group v-model="radio" class="full-btn-h">
                        <el-radio-button value="weekly" label="weekly">1 H</el-radio-button>
                        <el-radio-button value="monthly" label="monthly">1 OY</el-radio-button>
                        <el-radio-button value="six_monthly" label="six_monthly">6 OY </el-radio-button>
                        <el-radio-button value="yearly" label="yearly">1 Y </el-radio-button>
                    </el-radio-group>
                    <!-- <el-button
                        icon="el-icon-setting"
                        class="asosy-btn-d mt10"
                        @click="filterHome = true"
                    >
                    </el-button> -->
                </div>

                <div class="home-table">
                    <div id="chart">
                        <apexchart
                            type="line"
                            height="435"
                            :options="chartOptions"
                            :series="series"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <!-- end home-doctors-info -->

        <div class="my-modal">
            <el-drawer
                title="I am the title"
                :visible.sync="filterHome"
                :with-header="false"
                :append-to-body="true"
                size="40%"
                custom-class="my-modal-khan"
            >
                <FilterApplication></FilterApplication>
            </el-drawer>
        </div>
    </div>
</template>
    <script>
import FilterApplication from "./components/applicationFilter";
export default {
    props: {
        chart_data: {
            type: Array,
            required: true
        }
    },
    components: {
        FilterApplication,
    },
    data() {
        return {
            radio: "weekly",
            filterHome: false,
            series: [
                {
                    name: "Kelib tushgan",
                    data: [],
                },
                {
                    name: "Ko'rib chiqilmoqda",
                    data: [],
                },
                {
                    name: "Tasdiqlangan",
                    data: [],
                },
                {
                    name: "Qaytarilgan",
                    data: [],
                },
                {
                    name: "Bekor qilingan",
                    data: [],
                },
            ],

            chartOptions: {
                chart: {
                    height: 435,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#1ab7ea", "#0084ff", "#39539E", "#feb019", "#ff6178"],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },
                // title: {
                //     text: "Product Trends by Month",
                //     align: "left",
                // },
                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                    colors: [
                        "#1ab7ea",
                        "#0084ff",
                        "#39539E",
                        "#feb019",
                        "#ff6178",
                    ],
                },
                xaxis: {
                    categories: [],
                },
            },
        };
    },
    watch: {
        radio: {
            handler: function() {
                this.$emit('run-fetch-data', this.radio);
            },
            deep: true,
            immediate: true
        },
        $props: {
            handler() {
                let lables = [], data1 = [], data2 = [], data3 = [], data4 = [], data5 = [];
                _.forEach(this.chart_data, item => {
                    lables.push(item.label);
                    data1.push({x: item.label, y: item.count_new});
                    data2.push({x: item.label, y: item.count_rejected});
                    data3.push({x: item.label, y: item.count_returned});
                    data4.push({x: item.label, y: item.count_review});
                    data5.push({x: item.label, y: item.count_succeed});
                });

                this.chartOptions.xaxis = { categories: lables };
                this.series = [
                    {
                        name: 'Kelib tushgan',
                        data: data1
                    },
                    {
                        name: 'Ko\'rib chiqilmoqda',
                        data: data4
                    },
                    {
                        name: "Tasdiqlangan",
                        data: data5
                    },
                    {
                        name: "Qaytarilgan",
                        data: data3
                    },
                    {
                        name: "Bekor qilingan",
                        data: data2
                    }
                ];
            },
            deep: true,
            immediate: true
        }
    }
};
</script>
