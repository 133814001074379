export const model = {
    id: null,
    clinic: null,
    legal_entity_name: "",
    brand_name: "",
    type_id: null,
    email: "",
    phone_number: "",
    region_id: null,
    city_district_id: null,
    time_of_registered: "",
    address: "",
    filial_image_id: null,
    created_at: "",
    updated_at: "",
};
